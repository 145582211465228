const SidebarPostsConts = [
  {
    title: "Why Top Executives Choose Ghostwriters",
    date: "November 29, 2024",
    url: "/ktn/afroprenuership/why-top-executives-choose-ghostwriters",
  },

  {
    title: "How a Ghostwriter Can Help You Establish Thought Leadership Through a Book",
    date: "November 22, 2024",
    url: "/ktn/afroprenuership/how-a-ghostwriter-can-help-you-establish-thought-leadership-through-a-book",
  },

  {
    title: "How to Create A Tempting Ghostwriting Offerr",
    date: "November 15, 2024",
    url: "/ktn/afroprenuership/how-to-create-a-tempting-ghostwriting-offer",
  },
  {
    title: "7 Proven Strategies To Earn More Money As A Ghostwriter",
    date: "November 08, 2024",
    url: "/ktn/afroprenuership/seven-proven-strategies-to-earn-more-money-as-a-ghostwriter",
  },
  {
    title: "How Ghostwriters Can Overcome Anxiety When Sending Their First Pitch",
    date: "November 01, 2024",
    url: "/ktn/afroprenuership/how-ghostwriters-can-overcome-anxiety-when-sending-their-first-pitch",
  },

  {
    title: "How Ghostwriters Can Overcome Anxiety When Sending Their First Pitch",
    date: "October 25, 2024",
    url: "/ktn/afroprenuership/how-ghostwriters-can-overcome-anxiety-when-sending-their-first-pitch",
  },



];

export default SidebarPostsConts;
