import React from 'react';
import './PostsGrid.css'
import { NavLink } from "react-router-dom";



const PostsGrid = ({ posts }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
      {posts.map((post, index) => (
        <div
          key={index}
          className="flex mb-4 card-animation"
          style={{ animationDelay: `${index * 1}s` }} // Staggered delay
        >
          <div className="flex-shrink-0 relative">
            <NavLink to={post.url}>
              <img
                src={post.image}
                alt={post.title}
                className="h-20 md:h-32 w-auto object-cover rounded-lg transition duration-300 transform hover:scale-110"
              />
            </NavLink>
          </div>
          <div className="ml-4 flex-1">
            <NavLink to={post.url} className="text-black-600 hover:underline text-xs md:text-sm font-bold">{post.title}</NavLink>
            <p className="text-gray-600 text-sm" style={{fontSize: '10px'}}>Date: {post.date}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PostsGrid;
