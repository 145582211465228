// Fbs.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { NavLink } from "react-router-dom";

import './ReadKtnProps.css';

const ReadKtnProps = ({ blog }) => {
  const { title, imageUrl, content, category, date, minsRead, relatedBlogs } = blog;
  const pageUrl = window.location.href;

  const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(pageUrl)}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(pageUrl)}`;
  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(title + ' ' + pageUrl)}`;

  return (
    <div className="max-w-4xl mx-auto p-4 mt-20 md:mt-40 md:mb-10">
      {/* Category, Date, Mins Read */}
      <div className="flex items-center justify-between text-gray-500 mb-2">
        <div className="flex items-center space-x-2">
          <span className="bg-[#14143a] text-white px-2 py-2 rounded-full" style={{ fontSize: '10px' }}>
            {category}
          </span>
          <span className="bg-gray-200 text-gray-700 px-2 py-2 rounded-full" style={{ fontSize: '10px' }}>
            {date}
          </span>
          <span className="bg-gray-200 text-gray-700 px-2 py-2 rounded-full" style={{ fontSize: '10px' }}>
            {minsRead}
          </span>
        </div>
      </div>

      {/* Title of Blog */}
      <h1 className="text-5xl font-bold mt-10">{title}</h1>

      {/* Share Icons - Pushed to the Right with Space Between */}
      <div className="flex items-center justify-end mt-10 space-x-4">
        <NavLink to={linkedInShareUrl} className="text-black hover:text-gray-700" target="_blank" rel="noopener noreferrer">
          <span className="share-icon">
            <FontAwesomeIcon icon={faLinkedin} className="text-base text-gray-600" />
          </span>
        </NavLink>
        <NavLink to={twitterShareUrl} className="text-black hover:text-gray-700" target="_blank" rel="noopener noreferrer">
          <span className="share-icon">
            <FontAwesomeIcon icon={faTwitter} className="text-base text-gray-600" />
          </span>
        </NavLink>
        <NavLink to={whatsappShareUrl} className="text-black hover:text-gray-700" target="_blank" rel="noopener noreferrer">
          <span className="share-icon">
            <FontAwesomeIcon icon={faWhatsapp} className="text-base text-gray-600" />
          </span>
        </NavLink>
      </div>

      {/* Blog Image */}
      <img src={imageUrl} alt="Blog" className="w-full mt-4 rounded-lg shadow-md transition-transform duration-300 hover:shadow-lg hover:scale-105" />

      {/* Blog Text */}
      <div className="mt-4 text-lg leading-relaxed">
        <p>{content}</p>
        {/* Add your blog text content here */}
      </div>

      {/* Related Blogs Section (Card Format) */}
      <div className="mt-20 border-t pt-6">
        <h2 className="text-2xl font-bold mb-4">Related KTN</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {relatedBlogs.map((blog, index) => (
            <div key={index} className="bg-gray-100 rounded-lg shadow-lg overflow-hidden transition duration-300 transform hover:scale-110">
              <img src={blog.relatedimageUrl} alt={`Related Blog ${index + 1}`} className="w-full h-38" />
              <div className="p-4">
                <h3 className="text-lg font-semibold mb-2">
                  <NavLink to={blog.link} className="text-blue-500 hover:underline text-sm">
                    {blog.title}
                  </NavLink>
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReadKtnProps;
