import AI from '../../Images/blogpics/marketingsales/AI.webp'
import CBE from '../../Images/blogpics/marketingsales/CBE.webp'
import WAH from '../../Images/blogpics/marketingsales/WAH.webp'
import LRAC from '../../Images/blogpics/marketingsales/LRAC.webp'
import NCSA from '../../Images/blogpics/marketingsales/NCSA.webp'
import ML from '../../Images/blogpics/marketingsales/ML.webp'
import LUMPSUM from '../../Images/blogpics/marketingsales/Lumpsum.webp'
import HSB from '../../Images/blogpics/marketingsales/HSB.webp'
import CEC from '../../Images/blogpics/marketingsales/CEC.webp'
import PS from '../../Images/blogpics/marketingsales/PS.webp'
import RCR from '../../Images/blogpics/marketingsales/RCR.webp'
import MCP from '../../Images/blogpics/marketingsales/MCP.webp'
import KGJ from '../../Images/blogpics/marketingsales/KGJ.webp'

const masPosts = [
  {
    title: "Practical Strategies to Kickstart Your Ghostwriting Journey",
    date: "May 03, 2024",

    image: KGJ,
    url: "/ktn/marketing-and-sales/practical-strategies-journey",
  },
  {
    title: "Mastering Cold Pitching: How to Approach Clients for Ghostwriting Projects in 2024",
    date: "April 26, 2024",

    image: MCP,
    url: "/ktn/marketing-and-sales/mastering-cold-pitching",
  },
  {
    title: "Building Stable Income with Retainers and Client Relationships",
    date: "April 12, 2024",

    image: RCR,
    url: "/ktn/marketing-and-sales/building-stable-income",
  },
  {
    title: "Persuasive Strategies to Win Over Customers",
    date: " March 22, 2024",

    image: PS,
    url: "/ktn/marketing-and-sales/persuasive-startegies",
  },
  {
    title: "Crafting Cold Emails that Convert: Proven strategies for success",
      date: "February 02, 2024",
    image: CEC,
    url: "/ktn/marketing-and-sales/crafting-cold-emails",
  },
  {
    title: "How to succeed in any business",
    date: "January 19, 2024",

    image: HSB,
    url: "/ktn/marketing-and-sales/succeed-in-any-business",
  },




  {
    title: "$1k/month contracts pay more than $10,000 lump sum projects",
    date: "October 11, 2023",

    image: LUMPSUM,
    url: "/ktn/marketing-and-sales/lumpsum-project",
  },
  {
    title: "Marketing is just like law, convince the jury!",
    date: "September 19, 2023",

    image: ML,
    url: "/ktn/marketing-and-sales/marketing-law",
  },



  {
    title: "If you hate the feeling of selling read this",
    date: "August 01, 2023",

    image: NCSA,
    url: "/ktn/marketing-and-sales/hate-the-feeling-of-selling",
  },
  {
    title: "How to land and retain clients",
    date: "June 21, 2023",

    image: LRAC,
    url: "/ktn/marketing-and-sales/land-and-retain-clients",
  }, {
    title: "Being of utility makes you a whore?",
    date: "May 15, 2023",

    image: WAH,
    url: "/ktn/marketing-and-sales/whore",
  },
  
  {
    title: "The cold outreach strategy making us money",
    date: "May 11, 2023",
    image: AI,
    url: "/ktn/marketing-and-sales/cold-outreach-strategy",
  },




  {
    title: "How to increase your percieved value",
    date: ": January 27, 2023",

    image: CBE,
    url: "/ktn/marketing-and-sales/increase-your-percieved-value",
  },
  // Additional AI posts...
];

export default masPosts;
