import React from "react";
import ReadKtnProps from "../ReadKtnProps";
import DNAYP from "../../../Images/blogpics/afroprenueship/DNAYP.webp";
import TFC from "../../../Images/blogpics/afroprenueship/TFC.webp";
import UPTMM from "../../../Images/blogpics/afroprenueship/UPTMM.webp";
import DM from "../../../Images/blogpics/afroprenueship/DM.webp";

const BillClientsAfro = () => {
  const blogData = {
    title: "  You can bill clients whatever you want",
    imageUrl: TFC,
    content: (
      <>
               <p className="mt-10" style={{ fontSize: "15px" }}>

          Give so much value they feel criminal not paying your worth!
          <br />
          No good deed goes unpunished these days…
          <br />
          <br />
          If they turn a blind eye (keen to exploit you) Laud your efforts so
          they salute the diamond in their rough.
          <br />
          Great work shouldn’t be rewarded by compliments alone …Agree? Or Nah
          <br />
          <br />
          Ensure your value to clients compounds over time so you compel great
          pay. Be a multi-dimensional utility and highlight your value if your
          efforts go maliciously unacknowledged.
          <br />
          <br />
          The commitment to deliver so much value it chills their spine can
          backfire if that client is just sinister; But keep dishing out value
          the world can verify as excellent…
          <br />
          <br />
          That way you stand on solid ground when you demand better. As a
          service provider, you can earn life-long relationships more valuable
          than Bitcoin but for survival's sake secure your coins along the way!
          <br />
          <br />
        </p>
      </>
    ),
    category: "Afroprenuerchip",
    date: "May 25, 2023 ",
    minsRead: "3mins read",
    relatedBlogs: [
      {
        relatedimageUrl: UPTMM,
        title: " You don't need to have talent, have leverage",
        link: "/ktn/afroprenuership/talent-leverage",
      },
      {
        relatedimageUrl: DNAYP,
        title:
          " Don’t Niche Away Your Profit: Why Diversification Is Key For Business Success",
        link: "/ktn/afroprenuership/dont-niche-away-your-profit",
      },
      {
        relatedimageUrl: DM,
        title: " Debunking Myths About Crediting Ghostwriters",
        link: "/ktn/afroprenuership/debunking-myths",
      },
    ],
  };

  return <ReadKtnProps blog={blogData} />;
};

export default BillClientsAfro;
