import React from "react";
import CopywritingOne from '../../../Images/ServicesImages/CopywritingOne.jpg';
import CopywritingTwo from '../../../Images/ServicesImages/CopywritingTwojpg.jpg';
import CopywritingThree from '../../../Images/ServicesImages/CopywritingThree.jpg';

const CopywritingServices = () => {
  // Array of services with image URLs, labels, and overlay text
  const services = [
    {
      image: CopywritingOne,
      label: "Email Outreach",
      text: `
      
      
      High conversion sales emails, call scripts, and pitches are just arguments; You must speak with a good volume of qualified leads using compelling arguments- We deliver and clients blow our trumpet!`,
    },
    {
      image: CopywritingTwo,
      label: "Website content",
      text: `Could be your book, product, brand, image, service, or ideology, we construct narratives to capture and sell your vision. Boost traffic, enhance SEO, establish authority`,
    },
    {
      image: CopywritingThree,
      label: "Social media",
      text: `Your part is living to create the content our job is telling your story by articulating and putting out content that sticks with your audience`,
    },
  ];

  return (
    <div className="max-w-6xl mx-auto py-10 px-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {services.map((service, index) => (
        <div
          key={index}
          className="relative group overflow-hidden rounded-lg shadow-lg cursor-pointer"
        >
          {/* Image */}
          <img
            src={service.image}
            alt="Ghostwriting Service"
            className="w-full h-96 object-cover transition-transform duration-500 group-hover:scale-110 group-hover:blur-sm"
          />

          {/* Label below the image */}
          <div className="text-center bg-gray-800 text-white text-sm font-semibold py-5">
            {service.label}
          </div>

          {/* Overlay text with HTML */}
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-500 p-4 text-center">
            <p
              className="text-white text-md font-semibold"
              dangerouslySetInnerHTML={{ __html: service.text }} // Render HTML
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default CopywritingServices;
