import React from 'react';

const CTA = () => {
  return (
    <div className="bg-blue-100 py-6 px-2 sm:py-10 sm:px-4 lg:px-8">
      <div className="max-w-7xl mx-auto text-center">
        <p className="mt-3 text-black text-xs md:text-lg">
          We’re ready to meet if you have books in you, Your business deserves
          exponential growth or you have a brand the world must know.
        </p>
        <div className="mt-6 sm:mt-8 flex justify-center">
          <a
           href="https://calendly.com/kalukalu/discovery-call"
           target="_blank"
           rel="noopener noreferrer"
            className="bg-blue-500 rounded-md py-2 sm:py-3 px-4 sm:px-6 font-semibold sm:text-xl text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-white text-xs md:text-sm"
          >
            LET'S MEET
          </a>
        </div>
      </div>
    </div>
  );
};

export default CTA;
