import React from "react";
import ReadKtnProps from "../ReadKtnProps";
import NCSA from "../../../Images/blogpics/marketingsales/NCSA.webp";
import HSB from "../../../Images/blogpics/marketingsales/HSB.webp";
import LUMPSUM from "../../../Images/blogpics/marketingsales/Lumpsum.webp";
import ML from "../../../Images/blogpics/marketingsales/ML.webp";

const MarketingLaw = () => {
  const blogData = {
    title: "Marketing is just like law, convince the jury!",
    imageUrl: ML,
    content: (
      <>
               <p className="mt-10" style={{ fontSize: "15px" }}>

          Delayed gratification is what the smart people call it but here’s the
          gist… <br />
          $1k/month allows YOU GROW ON THEM over several months- uncovering new
          ways to collaborate! <br />
          6months turns to a year $1k retainers become $5k <br /> <br />
          This works exceptionally when you service clients with grand visions.{" "}
          <br />
          They’d rather be partners than employers as EVERYBODY needs a support
          system. <br /> <br />
          As you aid them realize visions you gain several perks; <br />
          - You learn new problems you’re capable of solving <br />
          - You uncover new services to get paid for <br />
          - You build relationship with a visionary <br /> <br />
          The relationship will yield more than $10000. <br />
          - Think referrals and recommendations <br />
          - Think goodwill and fulfillment <br />
          - Think solid network <br />
          - Think clan morale <br /> <br />
          When visionaries find worthy partners, the working scope grows with
          time and multi-dimensional utilities are always relevant to the cause.{" "}
          <br /> <br />
          Clients driven by deep-rooted philosophy thrive in their businesses;
          Stick with them you’ll soar together. <br /> <br />
          There’s no dollar tag to great relationships. Yearn to solve multiple
          challenges you’ll earn more than one flimsy lump sum. <br /> <br />
        </p>
      </>
    ),
    category: "Marketing and sales",
    date: "September 19, 2023",    
    minsRead: "1min read",
    relatedBlogs: [
      
      {
        relatedimageUrl: HSB,
        title: "How to succeed in any business",
        link: "/ktn/marketing-and-sales/succeed-in-any-business",
      },
      {
        relatedimageUrl: LUMPSUM,
        title:
          "$1k/month contracts pay more than $10,000 lump sum projects",
        link: "/ktn/marketing-and-sales/lumpsum-project",

     
      },
      {
        relatedimageUrl: NCSA,
        title: "If you hate the feeling of selling read this",
        link: "/ktn/marketing-and-sales/hate-the-feeling-of-selling",
      },
    ],
  };

  return <ReadKtnProps blog={blogData} />;
};

export default MarketingLaw;
