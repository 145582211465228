import React from "react";
import ReadKtnProps from "../ReadKtnProps";
import TAT from "../../../Images/blogpics/thoughtofchop/TAT.webp";
import LEOD from "../../../Images/blogpics/thoughtofchop/LEOD.webp";
import TALKATIVES from "../../../Images/blogpics/thoughtofchop/talkatives.webp";
import ROB from "../../../Images/blogpics/thoughtofchop/ROB.webp";

const MeditationInsomnia = () => {
  const blogData = {
    title: "Meditation or Insomnia",
    imageUrl: TAT,
    content: (
      <>
               <p className="mt-10" style={{ fontSize: "15px" }}>

          3rd April 2023 -Thoughts I meditated on at 12:07 AM <br /> <br />
          1. LIMITED MINDSET resembles CONTENTMENT, check yourself. <br />{" "}
          <br />
          2. Fine line between GREED and UNDERVALUING your service; How should
          you walk that line to avoid falling on either end of the spectrum.{" "}
          <br /> <br />
          3. People will say you’re SPINELESS but if you eventually avoid
          catastrophe having run away earlier, they’ll say you’re PERCEPTIVE.{" "}
          <br /> <br />
          4. Dream big! If you don’t make it they’ll say you’re DELUSIONAL but
          if you do they’ll call you GENIUS. …Fair game <br /> <br />
          5. There’ll always be commentators in and around your life, DON’T be
          one. <br /> <br />
          6. Advice will come from loved ones stemming from the purest place in
          their hearts about what they believe is best for you. <br /> <br />
          Going against the grain is considered truant and dismissive but if
          your route works out, you’re considered independent and a born leader.{" "}
          <br /> <br />
          7. You will be wrong many times when you take charge of your life but
          the alternative is to be wrong many times because another person is in
          control of your life. <br /> <br />
          8. Will you CREATE an imperfect yet fulfilling life and take
          responsibility for wins and losses or BURDEN people who have their
          lives to live with the task of curating yours too. <br /> <br />
          9. Spending life COMPLAINING leads to jagged self-worth and quiet
          desperation; Embracing RESPONSIBILITY despite difficulties leads to
          impervious liberty of mind and fortitude of spirit even in imperfect
          conditions and outcomes. <br /> <br />
          10. Am I crazy, I should be ASLEEP.
          <br /> <br />
          What crossed your mind the last time you meditated?
          <br />
        </p>
      </>
    ),
    category: "Thoughts to chop",
    date: "May 06, 2023",
    minsRead: "1min read",
    relatedBlogs: [
      {
        relatedimageUrl: TALKATIVES,
        title: "We love it when you talk a lot!",
        link: "/ktn/thought-to-chop/talkatives",
      },
      {
        relatedimageUrl: ROB,
        title: "Don't rob the world of your story",
        link: "/ktn/thought-to-chop/rob-the-world",
      },
      {
        relatedimageUrl: LEOD,
        title: "Parents don't hate children, they speak different languages",
        link: "/ktn/thought-to-chop/parents-dont-hate-children",
      },
    ],
  };

  return <ReadKtnProps blog={blogData} />;
};

export default MeditationInsomnia;
