import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="bg-white py-24 lg:py-44 px-4 lg:px-32">
      <h1 className="text-3xl font-bold text-gray-900 mb-4">Privacy Policy for Kick&CO Synergy</h1>
      <p className="text-gray-700 mb-6">Last updated: November 4, 2024</p>
      <p className="text-gray-700 mb-4">
        At Kick&CO Synergy, accessible from <a href="https://www.kickandcosynergy.com" className="text-blue-500 underline">https://www.kickandcosynergy.com</a>, we are committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website.
      </p>
      <p className="text-gray-700 mb-4">
        In this privacy policy, references to “Kick&CO Synergy,” “us,” “we,” or “our” are all references to Kick&CO Synergy.
      </p>
      <p className="text-gray-700 mb-4">
        Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the site.
      </p>

      <h2 className="text-2xl font-semibold text-gray-900 mb-4">Information We Collect</h2>
      <p className="text-gray-700 mb-2">We collect different types of information to provide and improve our services to you.</p>

      <h3 className="text-xl font-semibold text-gray-900 mb-2">a) Personal Data</h3>
      <p className="text-gray-700 mb-4">
        While using our website, we may ask you to provide certain personally identifiable information ("Personal Data") that can be used to contact or identify you. This information may include, but is not limited to:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li className="text-gray-700">Name</li>
        <li className="text-gray-700">Email address</li>
        <li className="text-gray-700">Phone number</li>
        <li className="text-gray-700">Billing and shipping information</li>
        <li className="text-gray-700">Any other information you provide directly to us</li>
      </ul>

      <h3 className="text-xl font-semibold text-gray-900 mb-2">b) Usage Data</h3>
      <p className="text-gray-700 mb-4">
        We may also collect information on how the website is accessed and used ("Usage Data"). This Usage Data may include information such as:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li className="text-gray-700">Your computer's IP address</li>
        <li className="text-gray-700">Browser type and version</li>
        <li className="text-gray-700">The pages you visit</li>
        <li className="text-gray-700">Time and date of your visit</li>
        <li className="text-gray-700">Time spent on those pages</li>
        <li className="text-gray-700">Device identifiers and other diagnostic data</li>
      </ul>

      <h2 className="text-2xl font-semibold text-gray-900 mb-4">Use of Data</h2>
      <p className="text-gray-700 mb-4">
        Kick&CO Synergy uses the collected data for various purposes, including to:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li className="text-gray-700">Provide, operate, and maintain our website</li>
        <li className="text-gray-700">Improve and personalize your experience</li>
        <li className="text-gray-700">Process transactions and send you information, including confirmations and invoices</li>
        <li className="text-gray-700">Send you promotional and marketing communications (if you have opted in)</li>
        <li className="text-gray-700">Detect, prevent, and address technical issues</li>
        <li className="text-gray-700">Comply with legal obligations</li>
      </ul>

      <h2 className="text-2xl font-semibold text-gray-900 mb-4">Data Sharing and Disclosure</h2>
      <p className="text-gray-700 mb-4">
        We may share your personal data with third parties in the following situations:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li className="text-gray-700">
          <strong>Service Providers:</strong> We may share your information with third-party vendors, consultants, and other service providers who perform services on our behalf.
        </li>
        <li className="text-gray-700">
          <strong>Legal Compliance:</strong> We may disclose your personal information if required to do so by law or in response to valid requests by public authorities.
        </li>
        <li className="text-gray-700">
          <strong>Business Transfers:</strong> If we are involved in a merger, acquisition, or asset sale, your personal data may be transferred.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold text-gray-900 mb-4">Data Security</h2>
      <p className="text-gray-700 mb-4">
        The security of your data is important to us. We strive to use commercially acceptable means to protect your personal information, but please be aware that no method of transmission over the Internet is 100% secure. We cannot guarantee the absolute security of your data.
      </p>

      <h2 className="text-2xl font-semibold text-gray-900 mb-4">Your Data Protection Rights</h2>
      <p className="text-gray-700 mb-4">
        Depending on your location, you may have certain rights regarding your personal data, including:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li className="text-gray-700">The right to access, update, or delete your personal information.</li>
        <li className="text-gray-700">The right to object to or restrict the processing of your data.</li>
        <li className="text-gray-700">The right to data portability (receive your personal data in a usable format).</li>
        <li className="text-gray-700">The right to withdraw your consent at any time, if applicable.</li>
      </ul>
      <p className="text-gray-700 mb-4">
        If you wish to exercise any of these rights, please contact us at <a href="mailto:hello@kickandcosynegy.com" className="text-blue-500 underline">hello@kickandcosynegy.com</a>.
      </p>

      <h2 className="text-2xl font-semibold text-gray-900 mb-4">Third-Party Links</h2>
      <p className="text-gray-700 mb-4">
        Our website may contain links to other sites that are not operated by us. We are not responsible for the privacy practices or the content of third-party sites. We encourage you to review their privacy policies.
      </p>

      <h2 className="text-2xl font-semibold text-gray-900 mb-4">Children's Privacy</h2>
      <p className="text-gray-700 mb-4">
        Our website does not address individuals under the age of 18 ("Children"). We do not knowingly collect personally identifiable information from anyone under 18. If you are a parent or guardian and you are aware that your child has provided us with personal data, please contact us.
      </p>

      <h2 className="text-2xl font-semibold text-gray-900 mb-4">Changes to This Privacy Policy</h2>
      <p className="text-gray-700 mb-4">
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.
      </p>
      <p className="text-gray-700 mb-4">
        We encourage you to review this Privacy Policy periodically for any changes. Your continued use of the website after any changes signifies your acceptance of the updated policy.
      </p>

      <h2 className="text-2xl font-semibold text-gray-900 mb-4">Contact Us</h2>
      <p className="text-gray-700 mb-4">
        If you have any questions about this Privacy Policy, please contact us at:
      </p>
      <p className="text-gray-700 mb-4">
        Email: <a href="mailto:hello@kickandcosynegy.com" className="text-blue-500 underline">hello@kickandcosynegy.com</a><br />
        Website: <a href="https://kickandcosynergy.com" className="text-blue-500 underline">https://kickandcosynergy.com</a>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
