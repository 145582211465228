import SPC from "../../Images/blogpics/thoughtofchop/SPC.webp";
import STBGG from "../../Images/blogpics/thoughtofchop/STBGG.webp";
import TAT from "../../Images/blogpics/thoughtofchop/TAT.webp";
import LEOD from "../../Images/blogpics/thoughtofchop/LEOD.webp";
import MYLWTA from "../../Images/blogpics/thoughtofchop/MYLWTA.webp";
import ROB from "../../Images/blogpics/thoughtofchop/ROB.webp";
import TALKATIVES from "../../Images/blogpics/thoughtofchop/talkatives.webp";
import TEllYOURSTORY from "../../Images/blogpics/thoughtofchop/tellyourstory.webp";
import TRUTH from "../../Images/blogpics/thoughtofchop/TAHEAD.webp";
import IMS from "../../Images/blogpics/thoughtofchop/IMS.webp";
import PSC from "../../Images/blogpics/thoughtofchop/PSC.webp";
import FBS from "../../Images/blogpics/thoughtofchop/FBS.webp";

const ttcPosts = [
  {
    title: "Customer-Centric Marketing: The Foundation Of Business Success",
    date: "March 16, 2024",

    image: FBS,
    url: "/ktn/thought-to-chop/customer-centric-marketing",
  },
  {
    title: "Practical Strategies To Speak With Confidence",
    date: "March 09, 2024",

    image: PSC,
    url: "/ktn/thought-to-chop/speak-with-confidence",
  },
  {
    title: "Steps to overcoming imposter syndrome",
    date: "February 23, 2024",

    image: IMS,
    url: "/ktn/thought-to-chop/overcoming-imposter-syndrome",
  },
  {
    title: "The truth will get you ahead",
    date: "February 07, 2024",

    image: TRUTH,
    url: "/ktn/thought-to-chop/the-truth",
  },
  {
    title: "Tell your story",
    date: "January 12, 2024",

    image: TEllYOURSTORY,
    url: "/ktn/thought-to-chop/tell-your-story",
  },
  {
    title: "We love it when you talk a lot!",
    date: "October 12, 2023",

    image: TALKATIVES,
    url: "/ktn/thought-to-chop/talkatives",
  },
  {
    title: "Don't rob the world of your story",
    date: "September 21, 2023",

    image: ROB,
    url: "/ktn/thought-to-chop/rob-the-world",
  },
  {
    title: "Parents don't hate children, they speak different languages",
    date: "January 18, 2023",

    image: LEOD,
    url: "/ktn/thought-to-chop/parents-dont-hate-children",
  },
  {
    title: "Meditation or Insomnia",
    date: "May 06, 2023",

    image: TAT,
    url: "/ktn/thought-to-chop/meditation-or-insomnia",
  },
  {
    title: "Going to the gym isn't neccessary",
    date: "April 03, 2023",

    image: STBGG,
    url: "/ktn/thought-to-chop/gym-isnt-neccessary",
  },
  {
    title: "How original is your life",
    date: "June 21, 2023",

    image: MYLWTA,
    url: "/ktn/thought-to-chop/how-original-is-your-life",
  },
  {
    title: "Grow your confidence by 1000%",
    date: "June 01, 2023",

    image: SPC,
    url: "/ktn/thought-to-chop/grow-your-confidence",
  },
  // Additional marketing posts...
];

export default ttcPosts;
