import React from "react";
import ReadKtnProps from "../ReadKtnProps";
import PS from "../../../Images/blogpics/marketingsales/PS.webp";
import CEC from "../../../Images/blogpics/marketingsales/CEC.webp";
import LUMPSUM from "../../../Images/blogpics/marketingsales/Lumpsum.webp";
import ML from "../../../Images/blogpics/marketingsales/ML.webp";

const LumpSumProjects = () => {
  const blogData = {
    title: "$1k/month contracts pay more than $10,000 lump sum projects",
    imageUrl: LUMPSUM,
    content: (
      <>
               <p className="mt-10" style={{ fontSize: "15px" }}>

          High conversion” sales emails, scripts, and pitches are just
          arguments;
          <br />
          The sole purpose is to seduce, intrigue, or compel an audience.
          <br />
          But here’s the kicker!
          <br />
          Great messages fail falling on deaf ears.
          <br />
          Seeds only grow on fertile soil!
          <br />
          <br />
          It’s like breaking a woman’s heart hoping to marry her sister… best of
          luck!
          <br />
          Your argument for love hardly sticks- read the room!
          <br />
          <br />
          Speaking to qualified leads gets higher conversion percentages, but
          that’s one factor
          <br />
          Without the family drama, you still might not be “her type” but you’d
          have a shot with intriguing messaging.
          <br />
          How compelling is your argument?
          <br />
          <br />
          Lastly, one gesture MAY get her attention but 10 WILL get a response.
          (***might be cease and desist, but hey, at least then you’ll know)
          <br />
          <br />
          it’s a numbers game!
          <br />
          Argue in front of 20k qualified prospects you may convince just 500.
          How different would your business be if you had 500 new clients?
          <br />
          The third factor is the volume of qualified leads reached
          <br />
          <br />
          Nail the 3 factors you’ll celebrate a “high conversion” marketing
          campaign
        </p>
      </>
    ),
    category: "Marketing and sales",
    date: "October 11, 2023",
    minsRead: "1min read",
    relatedBlogs: [
      {
        relatedimageUrl: PS,
        title: "Persuasive Strategies to Win Over Customers",
        link: "/ktn/marketing-and-sales/persuasive-startegies",
      },
      {
        relatedimageUrl: CEC,
        title:
          "Crafting Cold Emails that Convert: Proven strategies for success",
        link: "/ktn/marketing-and-sales/crafting-cold-emails",
      },
      {
        relatedimageUrl: ML,
        title: "Marketing is just like law, convince the jury!",
        link: "/ktn/marketing-and-sales/marketing-law",
      },
    ],
  };

  return <ReadKtnProps blog={blogData} />;
};

export default LumpSumProjects;
