import React from "react";
import ReadKtnProps from "../ReadKtnProps";
import TAT from "../../../Images/blogpics/thoughtofchop/TAT.webp";
import LEOD from "../../../Images/blogpics/thoughtofchop/LEOD.webp";
import STBGG from "../../../Images/blogpics/thoughtofchop/STBGG.webp";
import MYLWTA from "../../../Images/blogpics/thoughtofchop/MYLWTA.webp";

const OriginalLife = () => {
  const blogData = {
    title: "How original is your life",
    imageUrl: MYLWTA,
    content: (
      <>
               <p className="mt-10" style={{ fontSize: "15px" }}>

          A life worth talking about must first be lived. Quality stories are
          never risk-averse tales. <br /> <br />
          It’s true for legends! All the greats referenced today- the Rosa
          Parks, Martin Luthers and Mandelas…. Their lives can’t be described as
          walking on paved paths! <br /> <br />
          Adversity shaped meaning and the voluntary adoption of responsibility
          yielded a life worth referencing. <br /> <br />
          Don’t just be tall when your counterparts are short. Stand and focus
          on getting taller, strategize for growth, not their downfall. <br />{" "}
          <br />
          Sometimes you’re only in competition with the thoughts you believe
          others are having. Free yourself! <br /> <br />
          …You’re doomed to your own autonomy, embrace it. <br /> <br />
          We exist for experiences and expression; <br />
          Express yourself purposefully let others experience the best of
          humanity through you
        </p>
      </>
    ),
    category: "Thoughts to chop",
    date: "June 21, 2023",
    minsRead: "1min read",
    relatedBlogs: [
      {
        relatedimageUrl: LEOD,
        title: "Parents don't hate children, they speak different languages",
        link: "/ktn/thought-to-chop/parents-dont-hate-children",
      },
      {
        relatedimageUrl: TAT,
        title: "Meditation or Insomnia",
        link: "/ktn/thought-to-chop/meditation-or-insomnia",
      },
      {
        relatedimageUrl: STBGG,
        title: "Going to the gym isn't neccessary",
        link: "/ktn/thought-to-chop/gym-isnt-neccessary",
      },
    ],
  };

  return <ReadKtnProps blog={blogData} />;
};

export default OriginalLife;
