import STABB from "../../Images/blogpics/afroprenueship/STABB.webp";

const FeaturedPostConts = {
  title: "The Secret to a Bestselling Book in 2025: Why CEOs Choose to Work with Ghostwriters",
  date: "December 06, 2024",
  description:
    "If you’re an avid reader, you’ve probably wondered how your favorite authors manage to release book after book, year after year. The surprising truth is that many of them don’t do it alone. In fact, some of the most successful and prolific authors rely on the expertise of ghostwriters to bring their stories to life...",
  image: STABB,
  author: "Afropreneurship",
};

export default FeaturedPostConts;
