import React from "react";
import Hero from "./Hero";
import Cta from "../Cta/Cta";
import Testimonial from "./Testimonial";
import OurWork from "./OurWork";
import OurExpertise from "./OurExpertise";
import Path from "./Path";

const Home = () => {
  return (
    <>
      <Hero />
      <OurExpertise />
      <Cta /> <Testimonial /> <Path />
      <OurWork />
      <Cta />
    </>
  );
};

export default Home;
