import React from "react";
import ReadKtnProps from "../ReadKtnProps";
import TEllYOURSTORY from "../../../Images/blogpics/thoughtofchop/tellyourstory.webp";
import LEOD from "../../../Images/blogpics/thoughtofchop/LEOD.webp";
import TALKATIVES from "../../../Images/blogpics/thoughtofchop/talkatives.webp";
import ROB from "../../../Images/blogpics/thoughtofchop/ROB.webp";

const ParentsChildren = () => {
  const blogData = {
    title: "Parents don't hate children, they speak different languages",
    imageUrl: LEOD,
    content: (
      <>
               <p className="mt-10" style={{ fontSize: "15px" }}>

          Light serves to expose or direct, depending on context and situation.
          Light coming into a situation can mean clarity and direction and in
          other situations, light thrown in will mean uncovering that which was
          previously hidden. <br /> <br />
          The same multi-meaning can be read into a range of concepts and the
          most intriguing examples can be found all over the human experience.{" "}
          <br /> <br />
          People can interpret gifts as a show of condescension and an attempt
          at belittling their person. <br /> <br />
          There’s a parable that tells a story of someone jumping in front of a
          bullet in an attempt to save another’s life, only for the saved to
          turn round and complain that the savior’s blood has stained their
          white shirt. <br /> <br />
          UNDERSTAND THIS: <br /> <br />
          An object’s perception affects their reception. <br /> <br />
          What that means is what you’re giving may not be what is being
          received. <br /> <br />
          When you shed light on someone’s path, you may do so with the
          intention of guiding them and illuminating their journey but what you
          might have unintentionally done is brought their doom trail to light
          literally. <br /> <br />
          You might have sent them spiraling into depression because with that
          light comes the reality that they’re headed toward destruction. <br />{" "}
          <br />
          You mean well but on the receiving end, it’s perceived differently.{" "}
          <br /> <br />
          How do you bridge the gap to ensure everyone is on the same page?{" "}
          <br /> <br />
          Have you ever wondered why children think parents hate them when
          parents discipline them? Parents only hope to correct a destructive
          habit but the kids receive a message of hatred. <br /> <br />
          The correction is rooted in love but both parties are essentially
          communicating with different languages. <br /> <br />
          If we communicate intentions clearly, maybe there’ll be more embrace
          of good deeds as what they are. <br /> <br />
          Maybe if we let people know what it takes to gift them, they’ll
          appreciate it more. Or maybe we should gift only to the limit of the
          receiver’s level of understanding. <br /> <br />
          If I bought a vehicle for a 2yr old, they certainly wouldn’t
          appreciate it as much as onlookers who frequent public transport
          systems… <br /> <br />
          When you shed light, try, and explain that you mean to direct, not to
          expose. Unless that is indeed your intention. <br /> <br />
          Always make sure you’re speaking the same language
          <br />
        </p>
      </>
    ),
    category: "Thoughts to chop",
    date: "January 18, 2023 ",
    minsRead: "1min read",
    relatedBlogs: [
      {
        relatedimageUrl: TEllYOURSTORY,
        title: "Tell your story",
        link: "/ktn/thought-to-chop/tell-your-story",
      },
      {
        relatedimageUrl: TALKATIVES,
        title: "We love it when you talk a lot!",
        link: "/ktn/thought-to-chop/talkatives",
      },
      {
        relatedimageUrl: ROB,
        title: "Don't rob the world of your story",
        link: "/ktn/thought-to-chop/rob-the-world",
      },
    ],
  };

  return <ReadKtnProps blog={blogData} />;
};

export default ParentsChildren;
