import React from "react";
import ReadKtnProps from "../ReadKtnProps";
import TEllYOURSTORY from "../../../Images/blogpics/thoughtofchop/tellyourstory.webp";
import IMS from "../../../Images/blogpics/thoughtofchop/IMS.webp";
import TALKATIVES from "../../../Images/blogpics/thoughtofchop/talkatives.webp";
import PSC from "../../../Images/blogpics/thoughtofchop/PSC.webp";

const Talkatives = () => {
  const blogData = {
    title: "We love it when you talk a lot!",
    imageUrl: TALKATIVES,
    content: (
      <>
               <p className="mt-10" style={{ fontSize: "15px" }}>

          For any ghostwriting project we love clients who talk “too much!”
          <br />
          <br />
          Like “yesssss keep going! …Tell us what it felt like to be you in
          those moments. Describe your emotions and thoughts!”
          <br />
          <br />
          When their descriptions are extensive the project is more alluring as
          our inner literary Picasso is incited
          <br />
          <br />
          A client once caught herself rambling and, in a cringe, said- “God! I
          hope this isn’t too much, are you good hearing all this?”
          <br />
          <br />
          (***Expecting a disgruntling response)
          <br />
          <br />
          we were like “huh? …Please keep going! Empty your mind! we want to
          know what it is to be you so as we write we’re only penning down what
          you mean.”
          <br />
          <br />
          We want to be an extension of your thoughts so when you read our
          drafts you feel like you indeed wrote it but forgot.
          <br />
          <br />
        </p>
      </>
    ),
    category: "Thoughts to chop",
    date: "October 12, 2023",
    minsRead: "1min read",
    relatedBlogs: [
      {
        relatedimageUrl: IMS,
        title: "Steps to overcoming imposter syndrome",
        link: "/ktn/thought-to-chop/overcoming-imposter-syndrome",
      },
      {
        relatedimageUrl: PSC,
        title: "Practical Strategies To Speak With Confidence",
        link: "/ktn/thought-to-chop/speak-with-confidence",
      },
      {
        relatedimageUrl: TEllYOURSTORY,
        title: "Tell your story",
        link: "/ktn/thought-to-chop/tell-your-story",
      },
    ],
  };

  return <ReadKtnProps blog={blogData} />;
};

export default Talkatives;
