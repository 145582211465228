import React from "react";
import GhostwritingOne from '../../../Images/ServicesImages/Ghostwriting_Book1.jpg';
import GhostwritingTwo from '../../../Images/ServicesImages/Ghostwriting_Book2.jpg';

const GhostwritingServices = () => {
  // Array of services with image URLs, labels, and overlay text
  const services = [
    {
      image: GhostwritingOne,
      label: "Fiction",
      text: `Scripts, Short stories, Poems<br /><br />
       We bring your wildest ideas to life. Words unspoken fade away, untold stories vanish. <br /><br />
       Don’t let yours be forgotten.`,
    },
    {
      image: GhostwritingTwo,
      label: "Non-Fiction",
      text: `Biography, memoir, self-help, speeches, business, thought leadership.<br /><br />
       We transform your real-life experiences into intriguing narratives. Your life is your intellectual property—one the government can't seize. Capitalize on that now!`,
    },
  ];

  return (
    <div className="max-w-4xl mx-auto py-10 px-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
      {services.map((service, index) => (
        <div
          key={index}
          className="relative group overflow-hidden rounded-lg shadow-lg cursor-pointer"
        >
          {/* Image */}
          <img
            src={service.image}
            alt="Ghostwriting Service"
            className="w-full h-96 object-cover transition-transform duration-500 group-hover:scale-110 group-hover:blur-sm"
          />

          {/* Label below the image */}
          <div className="text-center bg-gray-800 text-white text-sm font-semibold py-5">
            {service.label}
          </div>

          {/* Overlay text with HTML */}
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-500 p-4 text-center">
            <p
              className="text-white text-md font-semibold"
              dangerouslySetInnerHTML={{ __html: service.text }} // Render HTML
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default GhostwritingServices;
