import React from "react";
import ReadKtnProps from "../ReadKtnProps";
import AI from "../../../Images/blogpics/marketingsales/AI.webp";
import LRAC from "../../../Images/blogpics/marketingsales/LRAC.webp";
import WAH from "../../../Images/blogpics/marketingsales/WAH.webp";
import CBE from "../../../Images/blogpics/marketingsales/CBE.webp";

const ColdOutreachStrategy = () => {
  const blogData = {
    title: "The cold outreach strategy making us money",
    imageUrl: AI,
    content: (
      <>
               <p className="mt-10" style={{ fontSize: "15px" }}>

          Cold outreach is an art AI CAN’T help you with… <br />
          Your message is secondary! …finding where “ideal clients” nest
          determines the success rate of your campaign. <br /> <br />
          Ever been hunting? …you track a trail to find the pack. <br /> <br />
          Birds of a feather flock together, so find where they hang out before
          shooting. Effective cold outreach is the same. You’re hunting for
          clients in a jungle marketplace; Identify one and track their trail!{" "}
          <br /> <br />
          Chances are they relate with like-minded people with similar
          challenges. In every cluster of 10, at least 2 have mutual challenges.
          (Could be more) <br /> <br />
          They’ve identified themselves for you, find their clusters, and your
          message will be welcomed like music to a choir! <br /> <br />
          This strategy is HOW TO HIT BIRDS IN A FLOCK. Throwing stones almost
          guarantees you hit AT LEAST TWO… <br /> <br />
          Don’t worry about the ability to throw at first, how’s your ability to
          track? Focus on finding and speaking to the right flock. <br /> <br />
          Just putting out messages into the abyss is a (hopeful) outreach
          campaign strategy… (Hope isn’t a plan) <br /> <br />
          Proper tracking unravels avatar patterns and behavioral frameworks
          that increase the potency of your message as it’ll be landing on
          fertile soil! <br /> <br />
          Learning what connects avatar networks also informs what offers you
          can make to turn “maybe” to “I wish I’d met you sooner, how do I make
          a payment please?” <br /> <br />
          One avatar’s network is a treasure trove of potential leads! …if our
          network is our net worth, their network is your homework. <br />{" "}
          <br />
          It’ll be like hovering magnets over a toolbox of shiny objects;
          rest-assured metals will cling… Follow spanners to sheds they lay- you
          might end up finding a car.
          <br />
        </p>
      </>
    ),
    category: "Marketing and sales",
    date: "May 11, 2023",
    minsRead: "1min read",
    relatedBlogs: [
      {
        relatedimageUrl: LRAC,
        title: "How to land an retain clients",
        link: "/ktn/marketing-and-sales/land-and-retain-clients",
      },
      {
        relatedimageUrl: WAH,
        title: "Being of utility makes you a whore?",
        link: "/ktn/marketing-and-sales/whore",
      },
      {
        relatedimageUrl: CBE,
        title: "How to increase your percieved value",
        link: "/ktn/marketing-and-sales/increase-your-percieved-value",
      },
    ],
  };

  return <ReadKtnProps blog={blogData} />;
};

export default ColdOutreachStrategy;
