import React from "react";
import ReadKtnProps from "../ReadKtnProps";
import PSC from "../../../Images/blogpics/thoughtofchop/PSC.webp";
import TRUTH from "../../../Images/blogpics/thoughtofchop/TAHEAD.webp";
import IMS from "../../../Images/blogpics/thoughtofchop/IMS.webp";
import TEllYOURSTORY from "../../../Images/blogpics/thoughtofchop/tellyourstory.webp";


const TellYourStory = () => {
  const blogData = {
    title: "Tell your story",
    imageUrl: TEllYOURSTORY,
    content: (
      <>
               <p className="mt-10" style={{ fontSize: "15px" }}>



          Many times, you hear the phrase “Life is a movie,” and you probably think to yourself: “What does that even mean? Whose movie? Who’s paying? When did I audition for a role and how did I get it?”<br /><br />

If someone asked you to narrate your day, you will probably start from how the alarm woke you up early, how you had your bath, got ready for work or school, took the train and so on.<br /><br />

Or maybe something even different. Guess what: there might be an aspect of your day that was pretty dramatic, which you would spend a bit more time highlighting with a bit more glow, spark or smile.<br /><br />

In fact, it might change your mood entirely. The interesting thing, of course, is that you cannot narrate your day’s event blow-by-blow. Your brain was not wired to remember things in that way.<br /><br />

Whatever you say will be your point of view. Now, if we were to find one of the people you interacted with during the day about their exchange with you, their version would be different.<br /><br />

Same story, different actors. In your story, they were probably the antagonists. In theirs, you were the antagonist.<br /><br />

We simply live life according to the scripts written our minds. Our values and beliefs shape how we act out the different events.<br /><br />


<strong>YOUR EYES AND BRAIN CAPTURE THE SCENES</strong>
<br /><br />

A camera mirrors exactly how your eyes and brain function. The lenses take the snapshots, which is stored in the memory and can be trimmed, cut, fast-forwarded, slowed down etc.<br /><br />

In the same manner, if you were to go to France for your holiday, and you visit the Eiffel Tower, your eyes will take a picture of the monument and your brain stores it.<br /><br />

Whenever you reminisce your trip to France or are trying to narrate that story to a friend, the picture is recalled and it feels like a film reel.<br /><br />

Another beautiful thing is that your brain not only collects the visual information, it records information from other senses like taste, smell and sound.<br /><br />

Your entire holiday now plays like a movie scene in your mind years later. That is your story, your view. It is not necessarily the fact.<br /><br />


<strong>CONTROL THE NARRATIVE OF YOUR STORY</strong>
<br /><br />

People who have interacted with you at some point in life can share their version of your story. Unfortunately, not only will it not be the version you have, it may not be the version you want to tell.<br /><br />

This is why it is important to tell your story the way only you can tell it. You have to be deliberate and responsible about the side of you that you want people to see and recall.<br /><br />

Do what you want, be what you want, and say what you want. You have to live life on your terms and rules. You cannot be reactive to nature’s flowing scripts of different plots, twists and genres.<br /><br />

Many people will look to control how you act in their story’s version. It is important to be in control of your role.<br /><br />

This might mean saying NO to requests or taking business risks and venturing into projects, going on that adventure, buying that car, falling in love and marrying that special person.<br /><br />

It might be writing that book you’ve always wanted to write.
        </p>
      </>
    ),
    category: "Thoughts to chop",
    date: "January 12, 2024",
    minsRead: "1min read",
    relatedBlogs: [
      {
        relatedimageUrl: PSC,
        title: "Practical Strategies To Speak With Confidence",
        link: "/ktn/thought-to-chop/speak-with-confidence",
      },
      {
        relatedimageUrl: IMS,
        title: "Steps to overcoming imposter syndrome",
        link: "/ktn/thought-to-chop/overcoming-imposter-syndrome",
      },
      {
        relatedimageUrl: TRUTH,
        title: "The truth will get you ahead",
        link: "/ktn/thought-to-chop/the-truth",
      },
    ],
  };

  return <ReadKtnProps blog={blogData} />;
};

export default TellYourStory;
