import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import "./OurExpertise.css"; // Assuming you have a CSS file for styles

const OurExpertise = () => {
  const cardRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate-card");
        } else {
          entry.target.classList.remove("animate-card"); // Optionally remove the class when not intersecting
        }
      });
    }, {
      threshold: 0.5 // Adjust threshold as needed
    });

    const cards = cardRefs.current;
    cards.forEach((card) => {
      if (card) { // Check if card element is valid
        observer.observe(card);
      }
    });

    // Cleanup
    return () => {
      observer.disconnect(); // Disconnect the observer first
    };
  }, []);

  return (
    <div className="bg-blue-100 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="md:text-3xl text-2xl font-extrabold text-gray-900 mb-20 text-center">
          Our Expertise
        </h2>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          <div ref={(el) => (cardRefs.current[0] = el)} className="bg-white p-6 rounded-lg shadow-lg text-center transition-transform transform hover:scale-105 duration-600 w-72 h-96 mx-auto group relative">
            <a href="/ghostwriting">
              <h3 className="font-semibold mt-10 mb-6 text-sm md:text-md">Professional Ghostwriting</h3>
              <p className="mb-8 text-gray-600 text-xs">Your experience in life answers someone’s search for answers- that’s great content! We listen to digest your story, then package and present it in the best form to achieve a purpose. You can grow your authority in your industry, turn your experiences into passive income, curate your legacy, impact people’s lives, or do nothing…</p>
              <div className="absolute bottom-6 left-6">
                <NavLink to="/ghostwriting" className="text-blue-500 flex items-center text-xs md:text-md">Learn More
                  <span className="ml-2 w-6 h-6 flex items-center justify-center bg-white rounded-full shadow border group-hover:bg-blue-500 transition-colors duration-300">
                    <svg className="w-4 h-4 text-blue-500 group-hover:text-white transition-colors duration-300" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M10.293 14.707a1 1 0 010-1.414L13.586 10 10.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"/>
                      <path fillRule="evenodd" d="M6 10a1 1 0 011-1h8a1 1 0 110 2H7a1 1 0 01-1-1z" clipRule="evenodd"/>
                    </svg>
                  </span>
                </NavLink>
              </div>
            </a>
          </div>
          <div ref={(el) => (cardRefs.current[1] = el)} className="bg-white p-6 rounded-lg shadow-lg text-center transition-transform transform hover:scale-105 duration-300 w-72 h-96 mx-auto group relative">
            <a href="/copywriting">
              <h3 className="font-semibold mt-10 mb-6 text-sm md:text-md">Persuasive Copywriting</h3>
              <p className="mb-8 text-gray-600 text-xs">You can say “I’ve been a pilot for 20 years” or “I’ve flown commercial jets for over two decades” We compel sales for your business using the right words. The world rewards sensation before substance. Our words present your business as delivering something divine your customers can’t get elsewhere; they won’t just pay money, they’ll pay attention…</p>
              <div className="absolute bottom-6 left-6">
                <NavLink to="/copywriting" className="text-blue-500 flex items-center text-xs md:text-md">Learn More
                  <span className="ml-2 w-6 h-6 flex items-center justify-center bg-white rounded-full shadow border group-hover:bg-blue-500 transition-colors duration-300">
                    <svg className="w-4 h-4 text-blue-500 group-hover:text-white transition-colors duration-300" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M10.293 14.707a1 1 0 010-1.414L13.586 10 10.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"/>
                      <path fillRule="evenodd" d="M6 10a1 1 0 011-1h8a1 1 0 110 2H7a1 1 0 01-1-1z" clipRule="evenodd"/>
                    </svg>
                  </span>
                </NavLink>
              </div>
            </a>
          </div>
          <div ref={(el) => (cardRefs.current[2] = el)} className="bg-white p-6 rounded-lg shadow-lg text-center transition-transform transform hover:scale-105 duration-300 w-72 h-96 mx-auto group relative">
            <a href="/marketing">
              <h3 className="font-semibold mt-10 mb-6 text-sm md:text-md">Provoking Marketing and PR</h3>
              <p className="mb-8 text-gray-600 text-xs">We’ll make sure your image is recognizable then track your customers to position you right in their faces. If they aren’t set to buy what you’re selling, they’ll willingly market you to their circle. That’s how good we make you look. Your avatar’s network is a treasure trove of potential customers! If “network” is your “net worth”, their network is our homework. we market your brand so it sells itself…</p>
              <div className="absolute bottom-6 left-6">
                <NavLink to="/marketing" className="text-blue-500 flex items-center text-xs md:text-md">Learn More
                  <span className="ml-2 w-6 h-6 flex items-center justify-center bg-white rounded-full shadow border group-hover:bg-blue-500 transition-colors duration-300">
                    <svg className="w-4 h-4 text-blue-500 group-hover:text-white transition-colors duration-300" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M10.293 14.707a1 1 0 010-1.414L13.586 10 10.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"/>
                      <path fillRule="evenodd" d="M6 10a1 1 0 011-1h8a1 1 0 110 2H7a1 1 0 01-1-1z" clipRule="evenodd"/>
                    </svg>
                  </span>
                </NavLink>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurExpertise;
