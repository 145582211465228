import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import KickImage from "../../Images/KickLogo/KickLogo.svg";
import "./Navbar.css";

const Navbar = () => {
  const [showLinksNavbar, setShowLinksNavbar] = useState(true);
  const [navbarSlideIn, setNavbarSlideIn] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // States for dropdowns
  const [hoverGhostwriting, setHoverGhostwriting] = useState(false);
  const [hoverCopywriting, setHoverCopywriting] = useState(false);
  const [hoverMarketing, setHoverMarketing] = useState(false);

  useEffect(() => {
    setNavbarSlideIn(true); // Trigger the slide-in effect on component mount

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const threshold = 100; // Adjust this value as needed

      setShowLinksNavbar(currentScrollY <= threshold);
    };

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMobileMenuOpen]);

  const navbarClasses = showLinksNavbar || isMobileMenuOpen
  ? "bg-[#14143a] p-4 fixed w-full z-10 top-16 transition-opacity duration-300 ease-in-out"
  : "bg-[#14143a] p-4 fixed w-full z-10 top-16 opacity-0 pointer-events-none";

const toggleMobileMenu = () => {
  setIsMobileMenuOpen(!isMobileMenuOpen);
  if (!isMobileMenuOpen) {
    setShowLinksNavbar(true); // Show navbar when menu opens
  }
};


  return (
    <div>
      <nav className="bg-blue-100 fixed w-full z-10 top-0">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="flex items-center justify-center h-16">
            <NavLink to="/" className="text-white text-lg font-bold">
              <img src={KickImage} alt="Logo" className="h-auto w-48 md:w-80" />
            </NavLink>
            <button
              className="text-black font-bold absolute right-4 lg:hidden"
              onClick={toggleMobileMenu}
            >
              {isMobileMenuOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="w-6 h-6"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="w-6 h-6"
                >
                  <line x1="3" y1="12" x2="21" y2="12" />
                  <line x1="3" y1="6" x2="21" y2="6" />
                  <line x1="3" y1="18" x2="21" y2="18" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </nav>
      <nav
        className={`${navbarClasses} ${
          isMobileMenuOpen
            ? "fixed inset-0 bg-[#14143a] flex items-center justify-center pt-8"
            : "hidden"
        } lg:block`}
      >
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="flex flex-col lg:flex-row items-center justify-center h-screen lg:h-auto">
            <div
              className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-4 nav-links-container"
              style={{ fontSize: "12px" }}
            >
              <NavLink
                to="/"
                className={({ isActive }) =>
                  `text-gray-400 hover:text-white px-3 py-2 rounded-md font-medium ${
                    navbarSlideIn ? "nav-link-slide-in nav-link-1" : ""
                  } ${isActive ? "text-white" : ""}`
                }
              >
                Home
              </NavLink>
              <div
                className="relative"
                onMouseEnter={() => setHoverGhostwriting(true)}
                onMouseLeave={() => setHoverGhostwriting(false)}
              >
                <NavLink
                  to="/ghostwriting"
                  className={({ isActive }) =>
                    `text-gray-400 hover:text-white px-3 py-2 rounded-md font-medium ${
                      navbarSlideIn ? "nav-link-slide-in nav-link-2" : ""
                    } ${isActive ? "text-white" : ""}`
                  }
                >
                  Professional Ghostwriting
                </NavLink>
                {/* {hoverGhostwriting && (
                  <div className="absolute top-6 left-0 bg-[#14143a] rounded-md shadow-lg p-4">
                    <NavLink
                      to="/fiction"
                      className="block px-4 py-2 text-gray-400 hover:text-white "
                    >
                      Fiction
                    </NavLink>
                    <NavLink
                      to="/self-help"
                      className="block px-4 py-2 text-gray-400 hover:text-white "
                    >
                      Self Help
                    </NavLink>
                    <NavLink
                      to="/children"
                      className="block px-4 py-2 text-gray-400 hover:text-white "
                    >
                      Children’s
                    </NavLink>
                    <NavLink
                      to="/speeches"
                      className="block px-4 py-2 text-gray-400 hover:text-white "
                    >
                      Speeches
                    </NavLink>
                    <NavLink
                      to="/blog-content"
                      className="block px-4 py-2 text-gray-400 hover:text-white "
                    >
                      Blog Content
                    </NavLink>
                    <NavLink
                      to="/business-books"
                      className="block px-4 py-2 text-gray-400 hover:text-white "
                    >
                      Business Books
                    </NavLink>
                    <NavLink
                      to="/general-nonfiction"
                      className="block px-4 py-2 text-gray-400 hover:text-white "
                    >
                      General Nonfiction
                    </NavLink>
                    <NavLink
                      to="/memior-autobiograpghy"
                      className="block px-4 py-2 text-gray-400 hover:text-white "
                    >
                      Memoir/Autobiography
                    </NavLink>
                    <NavLink
                      to="/thought-leadership"
                      className="block px-4 py-2 text-gray-400 hover:text-white "
                    >
                      Thought leadership
                    </NavLink>
                  </div>
                )} */}
              </div>
              <div
                className="relative"
                onMouseEnter={() => setHoverCopywriting(true)}
                onMouseLeave={() => setHoverCopywriting(false)}
              >
                <NavLink
                  to="/copywriting"
                  className={({ isActive }) =>
                    `text-gray-400 hover:text-white px-3 py-2 rounded-md font-medium ${
                      navbarSlideIn ? "nav-link-slide-in nav-link-3" : ""
                    } ${isActive ? "text-white" : ""}`
                  }
                >
                  Persuasive Copywriting
                </NavLink>
                {/* {hoverCopywriting && (
                  <div className="absolute top-6 left-0 bg-[#14143a] text-white rounded-md shadow-lg p-4">
                    <NavLink
                      to="/email-outreach"
                      className="block px-4 py-2 text-gray-400 hover:text-white "
                    >
                      Email Outreach
                    </NavLink>
                    <NavLink
                      to="/website-content"
                      className="block px-4 py-2 text-gray-400 hover:text-white "
                    >
                      Website content
                    </NavLink>
                    <NavLink
                      to="/social-media"
                      className="block px-4 py-2 text-gray-400 hover:text-white "
                    >
                      Social media
                    </NavLink>
                  </div>
                )} */}
              </div>
              <div
                className="relative"
                onMouseEnter={() => setHoverMarketing(true)}
                onMouseLeave={() => setHoverMarketing(false)}
              >
                <NavLink
                  to="/marketing"
                  className={({ isActive }) =>
                    `text-gray-400 hover:text-white px-3 py-2 rounded-md font-medium ${
                      navbarSlideIn ? "nav-link-slide-in nav-link-4" : ""
                    } ${isActive ? "text-white" : ""}`
                  }
                >
                  Marketing and Public Relations
                </NavLink>
                {/* {hoverMarketing && (
                  <div className="absolute top-6 left-0 bg-[#14143a] rounded-md shadow-lg p-4">
                    <NavLink
                      to="/brand-strategy"
                      className="block px-4 py-2 text-gray-400 hover:text-white "
                    >
                      Brand Strategy
                    </NavLink>
                    <NavLink
                      to="/video-production"
                      className="block px-4 py-2 text-gray-400 hover:text-white "
                    >
                      Video Production
                    </NavLink>
                  </div>
                )} */}
              </div>
              <NavLink
                to="/readktn"
                className={({ isActive }) =>
                  `text-gray-400 hover:text-white px-3 py-2 rounded-md font-medium ${
                    navbarSlideIn ? "nav-link-slide-in nav-link-5" : ""
                  } ${isActive ? "text-white" : ""}`
                }
              >
                Read KTN
              </NavLink>
              <a
                href="https://www.youtube.com/@KICKTHENARRATIVE"
                target="_blank"
                rel="noopener noreferrer"
                className={`text-gray-400 hover:text-white px-3 py-2 rounded-md font-medium ${
                  navbarSlideIn ? "nav-link-slide-in nav-link-6" : ""
                }`}
              >
                Watch KTN
              </a>
              <a
                href={navbarSlideIn ? "#ktn-plus" : "#"} // Replace '#' with the actual link if needed
                className={`text-gray-400 hover:text-white px-3 py-2 rounded-md font-medium ${
                  navbarSlideIn ? "nav-link-slide-in nav-link-6" : ""
                }`}
              >
                KTN Plus
              </a>

              <NavLink
                to="/contact-us"
                className={({ isActive }) =>
                  `text-gray-400 hover:text-white px-3 py-2 rounded-md font-medium ${
                    navbarSlideIn ? "nav-link-slide-in nav-link-7" : ""
                  } ${isActive ? "text-white" : ""}`
                }
              >
                Contact Us
              </NavLink>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
