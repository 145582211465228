import React from "react";
import ReadKtnProps from "../ReadKtnProps";
import TAT from "../../../Images/blogpics/thoughtofchop/TAT.webp";
import LEOD from "../../../Images/blogpics/thoughtofchop/LEOD.webp";
import STBGG from "../../../Images/blogpics/thoughtofchop/STBGG.webp";
import ROB from "../../../Images/blogpics/thoughtofchop/ROB.webp";

const Gym = () => {
  const blogData = {
    title: "Going to the gym isn't neccessary",
    imageUrl: STBGG,
    content: (
      <>
               <p className="mt-10" style={{ fontSize: "15px" }}>

          Driving to the gym to run treadmills feels a bit odd knowing I
          could’ve run to the gym and back home as a full work-out session Here
          are some HUSH HUSH motivations for remaining a gym member-
          <br />
          <br />
          1. Sexual transmutation! …Yeah. Observing and channeling good energy
          from “attractive” people inspires productive work-out sessions - Like
          when your crush comes to watch your game, and suddenly you display
          pro-level skill for 90 minutes straight
          <br />
          <br />
          2. Healthy competition! Call it camaraderie or any other positive
          term, but witnessing my friend run the treadmill for 60 minutes
          straight before lifting some mighty kilograms makes me want to at
          least attempt the same feat or push the envelope, so I can flex on him
          for a mutual laugh.
          <br />
          <br />
          3. Belonging to a tribe! We all crave this. That’s why remote workers
          who agitated for the liberty to work from “home” still step out to
          “work” from coffee shops and co-working spaces sometimes. Working out
          for 30 minutes straight seems a short while with your tribe gingering
          you, but you may struggle to complete 15-minute sessions alone. Even
          lone wolves can achieve more with a tribe… I support wellness,
          fitness, health, and wholeness. Whatever motivates your discipline and
          commitment to good health, whether you exercise at the gym or do the
          work from home; kudos!
          
        </p>
      </>
    ),
    category: "Thoughts to chop",
    date: "April 03, 2023",
    minsRead: "1min read",
    relatedBlogs: [
      {
        relatedimageUrl: ROB,
        title: "Don't rob the world of your story",
        link: "/ktn/thought-to-chop/rob-the-world",
      },
      {
        relatedimageUrl: LEOD,
        title: "Parents don't hate children, they speak different languages",
        link: "/ktn/thought-to-chop/parents-dont-hate-children",
      },
      {
        relatedimageUrl: TAT,
        title: "Meditation or Insomnia",
        link: "/ktn/thought-to-chop/meditation-or-insomnia",
      },
    ],
  };

  return <ReadKtnProps blog={blogData} />;
};

export default Gym;
