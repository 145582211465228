import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faTasks,
  faPlay,
  faSync,
  faDraftingCompass,
  faClipboardList,
  faFileAlt,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import "./Path.css"; // Make sure to import your CSS file

const cards = [
  {
    icon: faPhone,
    title: "Clarity Call",
    description: "You’ll find out how much working with us will pay you",
  },
  {
    icon: faTasks,
    title: "Onboarding",
    description:
      "We’ll get the boring stuff out of the way so we focus on creating magic",
  },
  {
    icon: faPlay,
    title: "Work commences",
    description: "Our partnership begins so our worlds unite",
  },
  {
    icon: faSync,
    title: "Regular updates",
    description:
      "We’re always one text away for progress updates and check-ins",
  },
  {
    icon: faDraftingCompass,
    title: "Draft Delivery",
    description:
      "You’ll see results beyond your expectations for the first time",
  },
  {
    icon: faClipboardList,
    title: "Review notes",
    description: "We’re open to receiving more brilliant ideas from you",
  },
  {
    icon: faFileAlt,
    title: "Final draft",
    description:
      "You’ll have a first glance at what the world is yet to witness",
  },
  {
    icon: faStar,
    title: "Reviews",
    description:
      "You’ll confess to how well your vision has been executed and we’ll be humble",
  },
  // Add more cards as needed
];

const Path = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const cardsRef = useRef(null);

  useEffect(() => {
    // Function to check if screen width is mobile
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 640); // Adjust breakpoint as needed
    };

    // Initial check on component mount
    checkIsMobile();

    // Add event listener for window resize
    window.addEventListener("resize", checkIsMobile);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  // Function to handle previous card
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? cards.length - 1 : prevIndex - 1
    );
  };

  // Function to handle next card
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === cards.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="py-16 relative">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="text-center">
          <h2 className="md:text-3xl text-2xl font-extrabold text-gray-900 mb-20 text-center">
            The Path To Your Masterpiece
          </h2>
        </div>
        {/* Conditional rendering based on isMobile */}
        {isMobile ? (
          <div className="grid grid-cols-2 gap-10"> {/* Changed grid-cols-1 to grid-cols-2 */}
            {cards.map((card, index) => (
              <div
                key={index}
                className="card bg-white shadow-lg rounded-lg p-10 h-72 flex flex-col justify-between border border-gray-300 transition-transform duration-300 ease-in-out transform hover:scale-105 hover:border-gray-500"
              >
                <div className="flex justify-center mb-4">
                  <FontAwesomeIcon
                    icon={card.icon}
                    className="h-5 w-5 text-gray-700"
                  />
                </div>
                <div>
                  <h3
                    className="font-semibold justify-center text-sm text-center text-gray-900 mb-4"
                  >
                    {card.title}
                  </h3>
                  <p
                    className="text-gray-600 mb-4 justify-center text-center text-xs"
                  >
                    {card.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div
            ref={cardsRef}
            className="mt-10 grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-4"
          >
            {cards.slice(currentIndex, currentIndex + 4).map((card, index) => (
              <div
                key={index}
                className="card bg-white shadow-lg rounded-lg p-10 h-72 flex flex-col justify-between border border-gray-300 transition-transform duration-300 ease-in-out transform hover:scale-105 hover:border-gray-500"
              >
                <div className="flex justify-center mb-4">
                  <FontAwesomeIcon
                    icon={card.icon}
                    className="h-10 w-10 text-gray-700"
                  />
                </div>
                <div>
                  <h3
                    className="font-semibold justify-center text-center text-gray-900 mb-4"
                    style={{ fontSize: "16px" }}
                  >
                    {card.title}
                  </h3>
                  <p
                    className="text-gray-600 mb-4 justify-center text-center"
                    style={{ fontSize: "12px" }}
                  >
                    {card.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
        {/* Navigation buttons only shown on larger screens */}
        {!isMobile && (
          <div className="absolute top-0 left-0 mt-4 flex space-x-2"> {/* Adjusted position to the left */}
            <button
              onClick={handlePrev}
              className="bg-white rounded-full p-2 shadow-md hover:bg-gray-100 focus:outline-none"
            >
              <span className="sr-only">Previous</span>
              <svg
                className="h-6 w-6 text-gray-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 19l-7-7 7-7"
                ></path>
              </svg>
            </button>
            <button
              onClick={handleNext}
              className="bg-white rounded-full p-2 shadow-md hover:bg-gray-100 focus:outline-none"
            >
              <span className="sr-only">Next</span>
              <svg
                className="h-6 w-6 text-gray-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                ></path>
              </svg>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Path;
