import React from "react";
import ReadKtnProps from "../ReadKtnProps";
import NCSA from "../../../Images/blogpics/marketingsales/NCSA.webp";
import LRAC from "../../../Images/blogpics/marketingsales/LRAC.webp";
import WAH from "../../../Images/blogpics/marketingsales/WAH.webp";
import AI from "../../../Images/blogpics/marketingsales/AI.webp";

const Whore = () => {
  const blogData = {
    title: "Being of utility makes you a whore?",
    imageUrl: WAH,
    content: (
      <>
               <p className="mt-10" style={{ fontSize: "15px" }}>

          We’re all whores; we just sell different parts of ourselves” -Thomas
          Shelby, OBE.
          <br />
          <br />
          If you have no use, you’re useless. Your value lies in the parts of
          yourself you commercialize; Your mind, time, energy, or other
          (hopefully virtuous) elements of your being. Make your offering
          note-worthy, and get great at marketing!
          <br />
          <br />
          Deploy tools to compel attention and convince audiences… intrigue,
          suspense, knowledge, humor, silence, shock, mystery, controversy,
          emotion, etc.
          <br />
          <br />
          The ability to get people doing what they otherwise wouldn’t have (on
          their own) seen a need to, is powerful.
          <br />
          <br />
          Everyone uses marketing and sales techniques scripted or free flow-
          <br />
          To show one way better than another, distinguish and layout.
          <br />
          Convince and convey, illustrate, and portray, describe and direct,
          induce and seduce.
          <br />
          To compel and conduct, euphemize and gentrify ideas, package and
          deliver thought.
          <br />
          To make the inexplicable concrete, to show feelings.
          <br />
          To guide gently and suggest kindly, to nudge people drawing their own
          conclusions in directions favoring you…
          <br />
          <br />
          It’s the essence of clear communication, marketing, and sales.
          <br />
          <br />
          You need these skills for social and business interaction. Babies cry
          and look adorable to get parents’ attention, we’ve practiced since
          birth!
          <br />
          <br />
          Sales & marketing teams need to know the psychology of persuasion,
          trends can only get you so far. Coaches, leaders, Teachers,
          entrepreneurs, and preachers all need to have their marketing utensils
          sharpened.
          <br />
          <br />
          Whatever you’re selling, You need the ability to convince! Audiences
          will need swaying- from potential life partners to customers to
          parliament. Regardless of what part of yourself you commercialize to
          be of utility in life, getting good at marketing has only upsides.
          <br />
          <br />
          <br />
        </p>
      </>
    ),
    category: "Marketing and sales",
    date: "May 15, 2023",
    minsRead: "1min read",
    relatedBlogs: [
      {
        relatedimageUrl: NCSA,
        title: "If you hate the feeling of selling read this",
        link: "/ktn/marketing-and-sales/hate-the-feeling-of-selling",
      },
      {
        relatedimageUrl: LRAC,
        title: "How to land an retain clients",
        link: "/ktn/marketing-and-sales/land-and-retain-clients",
      },
      {
        relatedimageUrl: AI,
        title: "The cold outreach strategy making us money",
        link: "/ktn/marketing-and-sales/cold-outreach-strategy",
      },
    ],
  };

  return <ReadKtnProps blog={blogData} />;
};

export default Whore;
