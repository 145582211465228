import React from "react";
import ReadKtnProps from "../ReadKtnProps";
import TFC from "../../../Images/blogpics/afroprenueship/TFC.webp";
import UPTMM from "../../../Images/blogpics/afroprenueship/UPTMM.webp";
import IS from "../../../Images/blogpics/afroprenueship/IS.webp";
import CGPT from "../../../Images/blogpics/afroprenueship/CGPT.webp";

const OvercomeImposterSyndromeAfro = () => {
  const blogData = {
    title: " How to overcome imposter syndrome in any industry ",
    imageUrl: IS,
    content: (
      <>
               <p className="mt-10" style={{ fontSize: "15px" }}>

          Here’s how I overcame imposter syndrome!!
          <br />
          When I tell people I’m a writer, deep down, I understand that writing
          can be done by anybody. (***let’s face it, these are words we speak
          that are just strung together and somehow, we can attach monetary
          value to a body of work)
          <br />
          <br />
          I ask myself: “To the best of your ABILITY TO THINK, did you speak the
          whole TRUTH?”
          <br />
          <br />
          I’ve sold some bodies of work for say USD$2000 and someone else had
          seen it prior, who wouldn’t value it for a slice of pizza. (their
          loss!)
          <br />
          <br />
          But how do I reconcile the debate within my mind on if my work is
          worth any money?
          <br />
          <br />
          I ask myself: “To the best of your ABILITY TO THINK did you speak the
          whole TRUTH?”
          <br />
          <br />
          I understand I’m primarily paid to think, and that’s the difference
          between writers- their inclinations and schools of thought! (I’ll even
          debate this)
          <br />
          <br />
          I’ve written about how two persons can have the same experience with
          varying outcomes, and it’s the same here as we can consume the same
          research content and distill the information differently…
          <br />
          <br />
          Every writer is inclined in a certain way and when a client likes the
          way I’m inclined to think, that’s what they pay me for!
          <br />
          <br />
          …HERE’s THE GOOD PART
          <br />
          <br />
          I don’t serve everybody, …I can’t! I can only serve those in tandem
          with how my brain mechanism analyzes and dishes out content; …from
          copywriting projects to ghostwriting projects.
          <br />
          <br />
          Understanding that the uniqueness of my trains of thoughts as an
          individual is valuable not just for their originality, but for the
          proven net positives they have on my clients’ operations and needs
          makes me confident enough to call myself a writer and receive pay
          without feeling like an imposter.
          <br />
        </p>
      </>
    ),
    category: "Afroprenuerchip",
    date: " May 05, 2023",
    minsRead: "3mins read",
    relatedBlogs: [
      {
        relatedimageUrl: CGPT,
        title: " Cold outreach (Human vs Artificial Intelligence)",
        link: "/ktn/afroprenuership/cold-outreach ",
      },
      {
        relatedimageUrl: UPTMM,
        title: " You don't need to have talent, have leverage",
        link: "/ktn/afroprenuership/talent-leverage ",
      },
      {
        relatedimageUrl: TFC,
        title: "You can bill clients whatever you want",
        link: "/ktn/afroprenuership/bill-clients ",
      },
    ],
  };

  return <ReadKtnProps blog={blogData} />;
};

export default OvercomeImposterSyndromeAfro;
