import React, { useState, useRef, useEffect } from "react";
import FeaturedPost from "./FeaturedPost";
import SidebarPosts from "./SidebarPosts";
import PostsGrid from "./PostsGrid";
import Pagination from "./Pagination";
import ttcPost from "./ttcPost";
import masPosts from "./masPosts";
import aPosts from "./aPosts";
import "./ReadKtn.css"; // Import the CSS file

const useScrollReveal = () => {
  const elementsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-in");
          observer.unobserve(entry.target);
        }
      });
    });

    const currentElements = elementsRef.current.filter((el) => el); // Filter out null elements
    currentElements.forEach((el) => {
      observer.observe(el);
    });

    return () => {
      currentElements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  return elementsRef;
};

const ReadKtn = () => {
  const [ttcPage, setTtcPage] = useState(1);
  const [masPage, setMasPage] = useState(1);
  const [aPage, setAPage] = useState(1);
  const postsPerPage = 6;

  const handleTtcPageChange = (page) => {
    setTtcPage(page);
  };

  const handleMasPageChange = (page) => {
    setMasPage(page);
  };

  const handleAPageChange = (page) => {
    setAPage(page);
  };

  const ttcStart = (ttcPage - 1) * postsPerPage;
  const ttcEnd = ttcPage * postsPerPage;
  const masStart = (masPage - 1) * postsPerPage;
  const masEnd = masPage * postsPerPage;
  const aStart = (aPage - 1) * postsPerPage;
  const aEnd = aPage * postsPerPage;

  const elementsRef = useScrollReveal(); // Custom hook for scroll reveal

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      const h2Element = ref.current.querySelector('h2');
      if (h2Element) {
        window.scrollTo({
          top: h2Element.offsetTop - 100, // Adjusted to show from just above the h2
          behavior: "smooth",
        });
      }
    }
  };
  
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col mt-14 md:mt-32">
      <header className="bg-[#14143a] p-10 text-center text-white font-bold text-3xl">
        <a
          href="#thoughts-to-chop"
          onClick={() => scrollToRef(elementsRef.current[2])}
          className="text-gray-400 hover:text-white px-3 py-2 text-xs md:text-sm"
        >
          Thoughts to chop
        </a>{" "}
        <a
          href="#marketing-and-sales"
          onClick={() => scrollToRef(elementsRef.current[5])}
              className="text-gray-400 hover:text-white px-3 py-2 text-xs md:text-sm"
        >
          Marketing and Sales
        </a>{" "}
        <a
          href="#afroprenuership"
          onClick={() => scrollToRef(elementsRef.current[8])}
              className="text-gray-400 hover:text-white px-3 py-2 text-xs md:text-sm"
        >
          Afroprenuership
        </a>
      </header>
      <main className="flex-1 container max-w-7xl mx-auto p-4">
        <div className="flex flex-wrap -mx-4 mb-8">
          <div
            className="w-full lg:w-8/12 px-4 mb-8 lg:mb-0"
            ref={(el) => (elementsRef.current[0] = el)}
          >
            <FeaturedPost />
          </div>
          <div
            className="w-full lg:w-4/12 px-4"
            ref={(el) => (elementsRef.current[1] = el)}
          >
            <SidebarPosts />
          </div>
        </div>

        <div
          id="thoughts-to-chop"
          className="flex justify-between items-center mb-8 mt-20"
          ref={(el) => (elementsRef.current[2] = el)}
        >
          <h2 className="text-xl md:text-2xl font-bold">Thoughts to chop</h2>
        </div>
        <PostsGrid
          posts={ttcPost.slice(ttcStart, ttcEnd)}
          ref={(el) => (elementsRef.current[3] = el)}
        />

        <div className="mb-8">
          <Pagination
            currentPage={ttcPage}
            totalPages={Math.ceil(ttcPost.length / postsPerPage)}
            onPageChange={handleTtcPageChange}
          />
        </div>

        <div
          id="marketing-and-sales"
          className="flex justify-between items-center mb-4"
          ref={(el) => (elementsRef.current[5] = el)}
        >
          <h2 className="text-xl md:text-2xl  font-bold">Marketing and Sales</h2>
        </div>
        <PostsGrid
          posts={masPosts.slice(masStart, masEnd)}
          ref={(el) => (elementsRef.current[6] = el)}
        />

        <div className="mb-8">
          <Pagination
            currentPage={masPage}
            totalPages={Math.ceil(masPosts.length / postsPerPage)}
            onPageChange={handleMasPageChange}
          />
        </div>

        <div
          id="afroprenuership"
          className="flex justify-between items-center mb-4"
          ref={(el) => (elementsRef.current[8] = el)}
        >
          <h2 className="text-xl md:text-2xl  font-bold">Afroprenuership</h2>
        </div>
        <PostsGrid
          posts={aPosts.slice(aStart, aEnd)}
          ref={(el) => (elementsRef.current[9] = el)}
        />

        <div className="mb-8">
          <Pagination
            currentPage={aPage}
            totalPages={Math.ceil(aPosts.length / postsPerPage)}
            onPageChange={handleAPageChange}
          />
        </div>

        <p
          className="text-center mt-10"
          style={{ fontSize: "12px" }}
          ref={(el) => (elementsRef.current[11] = el)}
        >
          Interpretation and execution are how you’ll be unique because everybody
          faces adversity. It’s why some prodigies “outshine” their masters and
          some pale in comparison. Nobody can teach it because greatness results
          from the individuality of human experience… Authenticity is your ability
          to distinguish what logic and experiential relevance indicate from
          narratives establishments dictate. Kicking a narrative means probing it to
          ensure the programming you’re letting into your mind facilitates a
          positive stride toward your goals amongst other benefits. The first
          category of KTN is “Thoughts to chop” read posts about interesting
          narratives that we’ve chopped down for clear consumption.
        </p>
        <p
          className="text-center mt-10"
          style={{ fontSize: "12px" }}
          ref={(el) => (elementsRef.current[12] = el)}
        >
          Your push for goals demands that you market your interest well in the
          social environment and good marketing is simply packaging predicated on
          truth to pique interests. Check out posts about “marketing and sales” in
          that category of KTN. We’ve spent time with some of the best marketers
          Earth has to offer and picked up a few nuggets.
        </p>
        <p
          className="text-center mt-10"
          style={{ fontSize: "12px" }}          ref={(el) => (elementsRef.current[13] = el)}
        >
          The voluntary adoption of responsibility will make your life worth
          referencing and your adversities will shape meaning into your life.
          “Afropreneurship” is a category on KTN with posts about entrepreneurship
          from African minds. Amidst the telling times in the African and global
          business landscapes, we believe resilience is a spirit to be adopted.
          Heavy is the head that wears the crown, If you want pawn problems don’t
          aim at being king! We don’t believe in complaints, we’ve invested time in
          sharpening our value, and remain ready to share all we learn as we go.
        </p>
        <p
          className="text-center mt-10"
          style={{ fontSize: "12px" }}
          ref={(el) => (elementsRef.current[14] = el)}
        >
          One fact the existence of counterfeits tells you if nothing else is
          originals exist! As “smart” tools get more usual and commonplace,
          thinkers will be called upon to go even deeper in thought to excavate even
          smarter ideas for smarter tools. We’re ghostwriters and marketers but
          we’re most proud to be thinkers. KTN explores thoughts, narratives, and
          insight from our research, logic, and firsthand experience. Bear in mind
          that perfection as a peak is a fallacy that robs joy. We just create, we
          don’t complain. create an antithesis to our thesis if you object, we’d
          love to read you.
        </p>
      </main>
    </div>
  );
};

export default ReadKtn;
