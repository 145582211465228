import React, { useEffect, useState } from "react";
import GhostwritingImage from "../../../Images/pages/ghostImage.webp";
import Ralph from "../../../Images/testimonialpics/Ralph.webp";
import Susan from "../../../Images/testimonialpics/susan.webp";
import Christina from "../../../Images/testimonialpics/chritiana.webp";
import Travis from "../../../Images/testimonialpics/travis.webp";
import Eze from "../../../Images/testimonialpics/eze.webp";
import David from "../../../Images/testimonialpics/david.webp";
import OurWork from "../../Home/OurWork";
import Path from "../../Home/Path";
import { NavLink } from "react-router-dom";




const testimonials = [

  {
    name: "Susan Ritter",
    role: (
      <>
        Founder, Wealthy Wise Woman,
        <br />
        Greater Tampa Bay Area.
      </>
    ),
    image: Susan,
    rating: 5,
    header: "Great Storytellers",
    testimonial:
      "I believe, like being a composer, it is a gift to come up with a piece of writing that can move people to action- Only some people have this gift. I believe you are one of those people. For those who are not great at writing, Kick&co services are the answer to their problem. Oftentimes, telling our stories can be difficult. We want to put ourselves in the best light. We are a little too close to our own pain and pride to tell it in a way that is interesting to others. Having someone write it for us, great storytellers like the team here is a boon..",
  },
  {
    name: "Christina Beauchemin",
    role: (
      <>
        Author, Speaker, Educator, Let My Legacy Be Love, LLC,
        <br />
        Malden Bridge, New York, United States.
      </>
    ),
    image: Christina,
    rating: 5,
    header: "Wonderful Service",
    testimonial:
      "Ghostwriting is a skill. To catch the thoughts and voice of another person and put it into your words is a skill, not many people do well, but you are super talented, so I can see why you are successful. I think this is a wonderful service. So many people have great ideas and stories to tell, but they don't have the words. They don't understand the craft. In those many, many cases, this team can help them get a story told. I know I will recommend you! If creativity is not your thing and words are not your best asset, check out Kick’s services. They're great at what they do",
  },
  {
    name: "Travis Robbins",
    role: (
      <>
        Adventurer/Walker, The Connection Odyssey,
        <br />
        Watertown, South Dakota, United States.
      </>
    ),
    image: Travis,
    rating: 5,
    header: "Expertise and Openness",
    testimonial:
      "I want to send a big appreciation to Kick&co, I never thought I'd write a book until last year considering I didn't even like reading until last year. Your help, expertise, and openness to work with me is greatly appreciated",
  },
 

 
  {
    name: "Douglas Eze, CFF",
    role: (
      <>
        Founder & CEO, Largo Financial Services LLC,
        <br />
        Washington DC-Baltimore Area, United States.
      </>
    ),
    image: Eze,
    rating: 5,
    header: "Truly Remarkable",
    testimonial:
      "I love seeing a band of young people come together to create something and you have created something truly remarkable here; I happily recommend your services to people",
  },

  {
    name: "David D. Doerrier",
    role: (
      <>
        Presentation Empowerment Coach David D. Doerrier, LLC,
        <br />
        Atlanta Metropolitan Area, United States.
      </>
    ),
    image: David,
    rating: 5,
    header: "Adding Value and Creating Win-Win Partnerships",
    testimonial:
      "Your experience of ghostwriting is a great example of turning ideas into profitable ventures. It's all about adding value and creating win-win partnerships. Providing this valuable service has been your key to success. Well done!",
  },

  {
    name: "Ralph Danna P. A.",
    role: (
      <>
        Owner of Dannasales,
        <br />
        Spring Hill, Florida, United States.
      </>
    ),
    image: Ralph,
    rating: 5,
    header: "Unmatched Ghostwriting and Marketing Mastery",
    testimonial:
      "Kick&co creations are not mere narratives; they are exquisite tapestries of emotion and insight. Delve into their world for unmatched ghostwriting and marketing mastery. Beyond mere words, you'll be immersed in stories ignited by fervor and woven with depth. For those who demand extraordinary content and want to forge heartfelt connections with their audience, Kick&co is the pinnacle of artful expression.",
  },
];

const BlogContent = () => {
  const [loaded, setLoaded] = useState(false);



  useEffect(() => {
    // After component mounts, set loaded to true to trigger animation
    setLoaded(true);
  }, []);


  const [currentIndex, setCurrentIndex] = useState(0);

  const next = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 3) % testimonials.length);
  };

  const prev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 3 + testimonials.length) % testimonials.length
    );
  };

  const getCurrentTestimonials = () => {
    return testimonials.slice(currentIndex, currentIndex + 3);
  };

  return (
    <>
      <div
        className="relative bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${GhostwritingImage})` }}
      >
        <div className="relative bg-gray-900 bg-opacity-75 py-44 mt-10 md:py-60 md:mt-32">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className={`text-center ${loaded ? "slide-in" : ""}`}>
              <h1 className=" font-extrabold text-white text-3xl md:text-5xl">
                Everyone has a book in them. Short of words? Speak with a 6ft2”
                ghostwriter!
              </h1>
              <p className="mt-10 text-xl text-gray-300">
                Blog posts increase traffic to your site and help your SEO
                rankings with backlinks. Let’s create industry-specific content
                that cements your authority and keeps your website visitors
                engaged
              </p>
              <div className="mt-10 flex justify-center">
                <a
                  href="https://calendly.com/kalukalu/discovery-call"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-[#14143a] hover:bg-indigo-600 text-white py-4 px-3 rounded-lg text-sm font-semibold mr-4 transition duration-300"
                >
                  Free Discovery Call
                </a>
               <NavLink
                  to="/contact-us"
                  className="inline-block bg-[#14143a] hover:bg-indigo-600 text-white py-4 px-3 rounded-lg text-sm font-semibold ml-4 transition duration-300"
                >
                  Share your rough ideas
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <OurWork />
    

      <div className="max-w-[1600px] mx-auto py-12">
      <h3 className="text-center text-blue-500 mb-4 text-md md:text-lg font-bold">
        A+ RATED GHOSTWRITING AND MARKETING SERVICES
      </h3>
      <h2 className="text-3xl font-bold text-center mb-12">
        Recent Testimonials
      </h2>
      <div className="flex justify-between items-center">
        <button
          onClick={prev}
          className="text-3xl p-2 text-gray-600 hover:text-gray-800"
        >
          {"<"}
        </button>
        <div className="flex justify-center space-x-4 overflow-hidden">
          {getCurrentTestimonials().map((item, index) => (
            <div
              key={index}
              className="bg-white border border-gray-300 shadow-md rounded-lg p-6 w-96 h-[700px] flex-shrink-0 flex flex-col justify-between"
            >
              <div className="flex-grow">
                <div className="flex justify-between items-center mb-4">
                  <div>
                    {[...Array(item.rating)].map((_, i) => (
                      <span key={i} className="text-yellow-500">
                        ★
                      </span>
                    ))}
                  </div>
                </div>
                <p className="text-lg font-bold">{item.header}</p>
                <p className="text-gray-600 mb-4 mt-6 whitespace-pre-line">
                  {item.testimonial}
                </p>
              </div>
              <div className="flex items-center">
                <img
                  src={item.image}
                  alt={item.name}
                  className="w-12 h-12 rounded-full mr-4"
                />
                <div>
                  <p className="font-bold">{item.name}</p>
                  <p className="text-sm">{item.role}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button
          onClick={next}
          className="text-3xl p-2 text-gray-600 hover:text-gray-800"
        >
          {">"}
        </button>
      </div>
    </div>


    <div className="flex flex-col md:flex-row bg-blue-100 p-4 mb-10 md:p-10">
        <div className="flex-1 text-black p-6 md:p-0 md:text-sm md:pr-4">
          Your experience in life answers someone’s search for answers- that’s
          great content! We listen to digest your story, then package and
          present it in the best form to achieve a purpose. You can grow your
          authority in your industry, turn your experiences into passive income,
          curate your legacy, impact people’s lives, or do nothing…
        </div>
        <a
          href="https://calendly.com/kalukalu/30min?month=2023-09"
          target="_blank"
          rel="noopener noreferrer"
          className="mt-4 md:mt-0 md:ml-4 block bg-blue-500 hover:bg-red-600 text-white font-bold py-2 px-4 md:py-4 md:px-4 rounded text-center
                   sm:py-3 sm:px-6 sm:text-sm"
          // Adjusted padding and margins for better mobile and larger screen layout
        >
          BOOK A DISCOVERY CALL
        </a>
      </div>
      <Path />
    </>
  );
};

export default BlogContent;
