import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import ContactImage from "../../Images/ContactImages/contact.jpg";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [whatsisyourstory, setWhatsisyourstory] = useState("");
  const [service, setService] = useState("ghostwriting");
  const [readKTN, setReadKTN] = useState("yes");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const subject = "Contact Form Submission";
    const body = `Name: ${name}%0D%0AEmail: ${email}%0D%0AWhat's your story?: ${whatsisyourstory}%0D%0AService: ${service}%0D%0ARead KTN: ${readKTN}%0D%0AMessage: ${message}`;

    const mailtoLink = `mailto:info@kickandcosynergy.com?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
  };

  return (
    <div style={{ fontSize: "13px" }}>
      {/* Hero Section */}
      <div className="bg-[#14143a] text-white py-48 px-4 text-center">
        <h1 className="text-4xl font-bold mb-4">Contact Us</h1>
        <p className="text-md md:text-xl">
          Get in touch with us for any inquiries or questions
        </p>
      </div>

      {/* Contact Information and Form */}
      <div className="max-w-7xl mx-auto px-4 py-8 lg:flex lg:justify-between lg:px-16">
        {/* Left Part: Contact Information and Form */}
        <div className="lg:w-1/2 px-4 py-2 md:py-8">
          <div className="mb-6">
            <p className="mb-4">
              As AI saturates the content market with each passing second,
              authentic stories, brands, and content by humans will get rarer
              and more valuable like vintage cars and collectors’ art.
            </p>
            <p>
              Tell us your rough ideas, if you haven’t formed them that’s okay,
              we’ll listen to your story and brainstorm for you.
            </p>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <input
                  placeholder="Name"
                  className="w-full border border-gray-300 rounded-md p-4 focus:outline-none focus:border-blue-500"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  placeholder="Email"
                  className="w-full border border-gray-300 rounded-md p-4 focus:outline-none focus:border-blue-500"
                />
              </div>
            </div>
            <div
              className="grid grid-cols-1 sm:grid-cols-2 gap-4"
              style={{ fontSize: "13px" }}
            >
              <div>
                <label htmlFor="service" className="block mb-1 font-bold">
                  What service of ours do you need
                </label>
                <select
                  id="service"
                  value={service}
                  onChange={(e) => setService(e.target.value)}
                  name="service"
                  className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-blue-500"
                >
                  <option value="ghostwriting">Ghostwriting</option>
                  <option value="copywriting">Copywriting</option>
                  <option value="marketing">Marketing</option>
                </select>
              </div>
              <div>
                <label htmlFor="subscribe" className="block mb-1 font-bold">
                  Have you read KTN?
                </label>
                <select
                  onChange={(e) => setReadKTN(e.target.value)}
                  name="subscribe"
                  className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-blue-500"
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
            </div>
            <div>
              <textarea
                placeholder="Tell us your story..."
                className="w-full border border-gray-300 rounded-md p-4 focus:outline-none focus:border-blue-500"
                rows="4"
                value={whatsisyourstory}
                onChange={(e) => setWhatsisyourstory(e.target.value)}
              ></textarea>
            </div>{" "}
            <div>
              <textarea
                className="w-full border border-gray-300 rounded-md p-4 focus:outline-none focus:border-blue-500"
                rows="4"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Tell us something cool about you:"
              ></textarea>
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-[#14143a] text-white py-4 px-6 rounded-md hover:bg-[#4c9dc5] transition duration-300 font-bold"
              >
                SUBMIT
              </button>
            </div>
          </form>
        </div>

        {/* Right Part: Contact Details */}
        <div className="lg:w-1/2 px-4 py-8">
        <div className="mb-4">
  <img
    src={ContactImage}
    alt="Contact us"
    className="rounded-lg shadow-md transition-transform transform hover:scale-105 hover:opacity-90"
  />
</div>

          <div className="mb-4">
            <div className="mb-2">
              <p className="font-bold mb-1">Email</p>
              <div className="flex items-center mb-2">
                <div className="bg-white-200 p-2 rounded-md border border-grey-500">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="text-[#4c9dc5]"
                  />
                </div>
                <span className="ml-2">info@kickandcosynergy.com</span>
              </div>
            </div>
            <div className="mb-2 md:mt-5">
              <p className="font-bold mb-1">Phone Number</p>
              <div className="flex items-center">
                <div className="bg-white-200 p-2 rounded-md border border-grey-500">
                  <FontAwesomeIcon icon={faPhone} className="text-[#4c9dc5]" />
                </div>
                <span className="ml-2">+2349139070701</span>
              </div>
            </div>
          </div>
          <p className="font-bold md:mt-5">Follow us on</p>
          <div className="flex space-x-4 mt-2">
            <a
              href="https://www.linkedin.com/company/kick-co-synergy-ltd/"   target="_blank"
                    rel="noopener noreferrer"
              className="text-gray-500 hover:text-blue-500"
            >
              <div className="border border-grey-500 p-2 rounded-md">
                <FontAwesomeIcon icon={faLinkedin} className="text-[#0077B5]" />
              </div>
            </a>
            <a
              href="https://www.instagram.com/kick.thenarrative/?g=5"   target="_blank"
                    rel="noopener noreferrer"
              className="text-gray-500  hover:text-blue-500"
            >
              <div className="border border-grey-500 p-2 rounded-md">
                <FontAwesomeIcon icon={faInstagram} className="text-[#1DA1F2]" />
              </div>
            </a>
            <a
              href="https://www.youtube.com/@KICKTHENARRATIVE"   target="_blank"
                    rel="noopener noreferrer"
              className="text-gray-500 hover:text-blue-500"
            >
              <div className="border border-grey-500 p-2 rounded-md">
                <FontAwesomeIcon icon={faYoutube} className="text-red-700" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
