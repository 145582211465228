import React from "react";
import ReadKtnProps from "../ReadKtnProps";
import IS from "../../../Images/blogpics/afroprenueship/IS.webp";
import CGPT from "../../../Images/blogpics/afroprenueship/CGPT.webp";
import ERROR from "../../../Images/blogpics/afroprenueship/ERROR.webp";
import BILLIONDOLLAR from "../../../Images/blogpics/afroprenueship/Billiondollar.webp";

const TrialAndError = () => {
  const blogData = {
    title: "  Trial and error makes you wise",
    imageUrl: ERROR,
    content: (
      <>
               <p className="mt-10" style={{ fontSize: "15px" }}>

          We all have the audacity to hope, the energy to desire, but few
          possess the childishness to make attempts.
          <br />
          <br />
          You cannot pursue dreams while expecting reality all the way- The
          journey is unusual!
          <br />
          You must do what they’re not doing to get what they’re not getting;
          <br />
          <br />
          It’s daunting as your strides challenge their testimony of “what’s
          possible”
          <br />
          <br />
          All dots mustn’t connect at inception- there’s no blueprint for
          trailblazers.
          <br />
          Your routes and challenges will be unique but trajectory supersedes
          position and speed where vision is concerned.
          <br />
          If your visions don’t scare you, you’re limiting your capacity for
          grandeur!
          <br />
          Going after your heart’s desires is the meaningful way to live, what
          else is worth your time on earth?
          <br />
          <br />
          The childishness to make attempts makes you wise as you’ll teach
          others the paths you discover and secrets you uncover
        </p>
      </>
    ),
    category: "Afroprenuerchip",
    date: " September 17, 2023",
    minsRead: "3mins read",
    relatedBlogs: [
      {
        relatedimageUrl: BILLIONDOLLAR,
        title: " What is your cash cow?",
        link: "/ktn/afroprenuership/what-is-your-cash-cow ",
      },
      {
        relatedimageUrl: CGPT,
        title: " Cold outreach (Human vs Artificial Intelligence)",
        link: "/ktn/afroprenuership/cold-outreach ",
      },
      {
        relatedimageUrl: IS,
        title: "How to overcome imposter syndrome in any industry ",
        link: "/ktn/afroprenuership/overcome-imposter-syndrome ",
      },
    ],
  };

  return <ReadKtnProps blog={blogData} />;
};

export default TrialAndError;
