import React from "react";
import ReadKtnProps from "../ReadKtnProps";
import NCSA from "../../../Images/blogpics/marketingsales/NCSA.webp";
import LRAC from "../../../Images/blogpics/marketingsales/LRAC.webp";
import LUMPSUM from "../../../Images/blogpics/marketingsales/Lumpsum.webp";
import ML from "../../../Images/blogpics/marketingsales/ML.webp";

const FeelingOfSelling = () => {
  const blogData = {
    title: "If you hate the feeling of selling read this",
    imageUrl: NCSA,
    content: (
      <>
               <p className="mt-10" style={{ fontSize: "15px" }}>

          Don’t sell alcohol to people in remission to meet sales quotas, you’ll
          feel yuck! <br /> <br />
          Observe keyboards when crafting marketing narratives- “yoU” comes
          before “I” (it works best this way) <br />
          Care for clients before yourself so marketing won’t leave a sour
          aftertaste. <br /> <br />
          IF MARKETING FEELS YUCK, YOU’RE DOING IT WRONG <br /> <br />
          The aim is to land clients who NEED your service/product, not to land
          clients at any cost. Here’s a strategy <br /> <br />
          SWITCH MARKETING THEMES from “here’s why you need me” to “here’s how I
          meet your needs” <br /> <br />
          The former emphasizes their needs and CLAIMS you’re the solution; the
          latter suggests their needs are apparent to everyone and SHOWS you’re
          the solution. <br /> <br />
          IS IT CLEAR THEY NEED YOU? <br /> <br />
          Position as the viable option- Let them experience what it’d be like
          to have you or your product on their team; it’ll sway purchasing
          decisions in your favor. <br /> <br />
          Your job is to make sure you’re positioned in their view and can back
          up your claims! <br /> <br />
          AVOID DEMEANING CLIENT ACQUISITION PHASES <br /> <br />
          If 25 clients are your target, land one and tailor your marketing
          campaign to their Likeness. When you land one, there’s another in the
          ethos. Find the second one, and you’ll see there are 10 more. <br />{" "}
          <br />
          This perspective will make marketing easier ON you… Your conscience is
          clear; you’re making genuine money, your clients are having a
          transformative experience with your offerings… <br /> <br />
          Doesn’t make marketing easier FOR you though. <br /> <br />
          This isn’t a “hack” … <br /> <br />
          It’s just what you do to stop marketing from feeling YUCK. <br />
        </p>
      </>
    ),
    category: "Marketing and sales",
    date: "August 01, 2023",
    minsRead: "1min read",
    relatedBlogs: [
      {
        relatedimageUrl: LUMPSUM,
        title: "$1k/month contracts pay more than $10,000 lump sum projects",
        link: "/ktn/marketing-and-sales/lumpsum-project",
      },
      {
        relatedimageUrl: ML,
        title: "Marketing is just like law, convince the jury!",
        link: "/ktn/marketing-and-sales/marketing-law",
      },
      {
        relatedimageUrl: LRAC,
        title: "How to land an retain clients",
        link: "/ktn/marketing-and-sales/land-and-retain-clients",
      },
    ],
  };

  return <ReadKtnProps blog={blogData} />;
};

export default FeelingOfSelling;
