import React from "react";
import ReadKtnProps from "../ReadKtnProps";
import KGJ from "../../../Images/blogpics/marketingsales/KGJ.webp";
import LRAC from "../../../Images/blogpics/marketingsales/LRAC.webp";
import WAH from "../../../Images/blogpics/marketingsales/WAH.webp";
import CBE from "../../../Images/blogpics/marketingsales/CBE.webp";

const IncreasePercievedValue = () => {
  const blogData = {
    title: "How to increase your percieved value",
    imageUrl: CBE,
    content: (
      <>
               <p className="mt-10" style={{ fontSize: "15px" }}>

          You can earn $96k, $36k or $144k annually, depending on the month as a
          solopreneur. Content presented like that embodies the essence of
          copywriting, marketing, and sales. <br /> <br />
          Its packaging predicated on truth to pique interests… <br /> <br />
          I’ve just said “Some months, a solopreneur may earn $8k, in other
          months- $3k, and in others- $12k” You can say “I’ve been a pilot for
          20 years” or “I’ve flown commercial jets for over two decades” The
          latter packs more gravitas! <br /> <br />
          This representation strategy is effective in pitches, book marketing,
          ads, delivering reports to investors, etc. <br /> <br />
          Speak the truth and deliver a Bentley in what looks like a yacht. Goes
          without saying- you must have the Bentley! …You must have the
          substance! …Or at least be convinced you do <br /> <br />
          When marketing, articulate, formulate, and present: <br />
          1.Ensuring you take nothing away from your impeccable substance;{" "}
          <br /> <br />
          2.Ensuring your substance is represented by a status it may yet
          become. <br /> <br />
          You lose currency of PERCEIVED VALUE if you cannot market the right
          way even when you’re crème de la crème… <br /> <br />
          <br />
        </p>
      </>
    ),
    category: "Marketing and sales",
    date: "January 27, 2023",
    minsRead: "1min read",
    relatedBlogs: [
      {
        relatedimageUrl: LRAC,
        title: "How to land an retain clients",
        link: "/ktn/marketing-and-sales/land-and-retain-clients",
      },
      {
        relatedimageUrl: WAH,
        title: "Being of utility makes you a whore?",
        link: "/ktn/marketing-and-sales/whore",
      },
      {
        relatedimageUrl: KGJ,
        title: "Practical Strategies to Kickstart Your Ghostwriting Journey",
        link: "/ktn/marketing-and-sales/practical-strategies-journey",
      },
    ],
  };

  return <ReadKtnProps blog={blogData} />;
};

export default IncreasePercievedValue;
