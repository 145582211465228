import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'tailwindcss/tailwind.css';

import Image1 from '../../Images/testimonials/1.webp';
import Image2 from '../../Images/testimonials/2.webp';
import Image3 from '../../Images/testimonials/3.webp';
import Image4 from '../../Images/testimonials/4.webp';
import Image5 from '../../Images/testimonials/5.webp';
import Image6 from '../../Images/testimonials/6.webp';
import Image7 from '../../Images/testimonials/7.webp';
import Image8 from '../../Images/testimonials/8.webp';
import Image9 from '../../Images/testimonials/9.webp';
import Image10 from '../../Images/testimonials/10.webp';

const books = [
  { id: 1, image: Image1 },
  { id: 2, image: Image2 },
  { id: 3, image: Image3 },
  { id: 4, image: Image4 },
  { id: 5, image: Image5 },
  { id: 6, image: Image6 },
  { id: 7, image: Image7 },
  { id: 8, image: Image8 },
  { id: 9, image: Image9 },
  { id: 10, image: Image10 },
];

const OurWork = () => {
  const settings = {
    infinite: true,
    speed: 1600,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    pauseOnHover: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="max-w-[1600px] py-4 md:py-10 mx-auto">
      <Slider {...settings}>
        {books.map(book => (
          <div key={book.id} className="px-1 md:px-2">
            <img src={book.image} alt={`Book ${book.id}`} className="w-auto h-40 md:h-48 object-cover mx-auto"/>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default OurWork;
