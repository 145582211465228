import React from "react";
import ReadKtnProps from "../ReadKtnProps";
import UPTMM from "../../../Images/blogpics/afroprenueship/UPTMM.webp";
import IS from "../../../Images/blogpics/afroprenueship/IS.webp";
import CGPT from "../../../Images/blogpics/afroprenueship/CGPT.webp";
import ERROR from "../../../Images/blogpics/afroprenueship/ERROR.webp";

const ColdOutreachAfro = () => {
  const blogData = {
    title: "Cold outreach (Human vs Artificial Intelligence)  ",
    imageUrl: CGPT,
    content: (
      <>
               <p className="mt-10" style={{ fontSize: "15px" }}>

          CHATGPT GAVE SHALLOW ADVICE WHEN ASKED “HOW TO COLD OUTREACH” …LISTEN
          <br />
          UP! Show potential clients what you’ll do for them they don’t want to
          hear it.
          <br />
          <br />
          HERE’S COLD OUTREACH SCRIPTS FOR LINKEDIN:
          <br />
          “Hey (their name), I’ve studied the content you’ve been putting out to
          promote your (X) here’s 10 original posts I’ve created in your brand’s
          voice that’ll speak to your high-ticket customers I’ll appreciate your
          thoughts on them and please feel free to use them if you’d like”
          <br />
          <br />
          HERE’s ANOTHER: “Hey (their name), I’ve seen your (X) product and I’ve
          created these (X) to supercharge your marketing campaign… I’d love to
          get your thoughts and here’s a 2-page pdf spelling out more sales
          conversion ideas you should be implementing.
          <br />
          <br />
          We can easily refine them with a brief conversation if we need to or
          you can just roll with them Let me know…” Both texts compel responses
          using the same subtle sales technique. They’ll bear different fruits…
          PAY ATTENTION TO THIS
          <br />
          <br />
          1.Your prospect deems you a “forward thinker” and an “initiative
          taker” …you’ve done the work to study and deliver heat!
          <br />
          <br />
          2.You’re willing to give value free which does two things for you-
          <br />
          A. If you’ve done due diligence and deliver something incredibly
          useful, they could be inclined to pay you for the work
          <br />
          B. They may think “huh, if all this fire is free, the premium version
          must be something to behold” …you’ve made an impression!
          <br />
          3.You’ll induce a response (HIGH PROBABILITY) Everyone loves a “no
          obligation” gift. That’s what you’re giving. They can utilize your
          solution with zero burden. (Good enough to at least say thanks to you)
          <br />
          <br />
          4.When people put out marketing content, they crave feedback. You’ve
          not only given feedback you’re giving solutions that make their
          efforts more productive
          <br />
          <br />
          5.In the second script you’re making yourself part of their team
          saying “WE” …like their problem is yours. They’ll likely want to
          schedule that call.
          <br />
          <br />
          I’ve landed clients this way and gotten ignored but my batting average
          indicates I’ve won with this strategy way more.
          <br />
          <br />
          STOP knocking on doors, start kicking them in! Show what you’ll do for
          them, stop pitching “what’s possible” Your success-rate-determinant
          here is how potent the solution you deliver is. Posts, videos, Email
          funnel, Ideas etc. GIVE YOUR BEST
        </p>
      </>
    ),
    category: "Afroprenuerchip",
    date: " June 11, 2023",
    minsRead: "3mins read",
    relatedBlogs: [
      {
        relatedimageUrl: ERROR,
        title: "Trial and error makes you wise ",
        link: "/ktn/afroprenuership/trial-and-error  ",
      },
      {
        relatedimageUrl: IS,
        title: "How to overcome imposter syndrome in any industry ",
        link: "/ktn/afroprenuership/overcome-imposter-syndrome ",
      },
      {
        relatedimageUrl: UPTMM,
        title: " You don't need to have talent, have leverage",
        link: "/ktn/afroprenuership/talent-leverage ",
      },
    ],
  };

  return <ReadKtnProps blog={blogData} />;
};

export default ColdOutreachAfro;
