import React from "react";
import ReadKtnProps from "../ReadKtnProps";
import TEllYOURSTORY from "../../../Images/blogpics/thoughtofchop/tellyourstory.webp";
import TRUTH from "../../../Images/blogpics/thoughtofchop/TAHEAD.webp";
import TALKATIVES from "../../../Images/blogpics/thoughtofchop/talkatives.webp";
import ROB from "../../../Images/blogpics/thoughtofchop/ROB.webp";

const RobtheWorld = () => {
  const blogData = {
    title: "Don't rob the world of your story",
    imageUrl: ROB,
    content: (
      <>
               <p className="mt-10" style={{ fontSize: "15px" }}>

          Your experience answers someone’s search for answers- tell your story,
          and offer that service! You’ve lived and learned, don’t leave with the
          lessons; <br /> <br />
          Yearning for impact over recognition you’ll be recognized for your
          impact. <br />
          Be ecstatic leaving one positive impression as you would a million.{" "}
          <br />
          Celebrate one or you’ll remain discontent after a million. <br />{" "}
          <br />
          Make your strides a mission to serve rather than a bid to achieve.{" "}
          <br />
          This applies to authors, service providers, and everyone who breathes
          air. <br /> <br />
          Offering the world your substance is noble, don’t despise days of
          “little” impact. <br />
          Serendipity dictates your role is to position and let the divine go to
          work- who should be impacted will. <br /> <br />
          Big people reached out to celebrate reading us on LinkedIn. I’m
          intrigued… <br />
          Ghost-consumers reading ghostwriters; Digital spaces are eerie no?
          …people see you! <br /> <br />
          If you breathe air, you have something to offer the world- Your
          experience is worth more than real estate. <br />
          (Quote us.) <br /> <br />
          Before your impact is “large” take solace in the quality of your
          content. <br />
          Deliver your best with the exposure and information at your disposal,
          your best gets better in phases. <br />
          You’ll soon appreciate the conviction to JUST START when you’re
          reminiscent. <br />
          Distance your brand from mediocrity. With two impressions or a
          million, quality dished should mimic the standards you aspire to hone.{" "}
          <br /> <br />
          Utilizing what you have yields what you want. Share your story,
          expertise, and insight.
          <br />
        </p>
      </>
    ),
    category: "Thoughts to chop",
    date: "September 21, 2023",
    minsRead: "1min read",
    relatedBlogs: [
      {
        relatedimageUrl: TRUTH,
        title: "The truth will get you ahead",
        link: "/ktn/thought-to-chop/the-truth",
      },
      {
        relatedimageUrl: TEllYOURSTORY,
        title: "Tell your story",
        link: "/ktn/thought-to-chop/tell-your-story",
      },
      {
        relatedimageUrl: TALKATIVES,
        title: "We love it when you talk a lot!",
        link: "/ktn/thought-to-chop/talkatives",
      },
    ],
  };

  return <ReadKtnProps blog={blogData} />;
};

export default RobtheWorld;
