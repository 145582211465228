import React from "react";
import ReadKtnProps from "../ReadKtnProps";
import TAT from "../../../Images/blogpics/thoughtofchop/TAT.webp";
import SPC from "../../../Images/blogpics/thoughtofchop/SPC.webp";
import STBGG from "../../../Images/blogpics/thoughtofchop/STBGG.webp";
import MYLWTA from "../../../Images/blogpics/thoughtofchop/MYLWTA.webp";

const GrowYourConfidence = () => {
  const blogData = {
    title: "Grow your confidence by 1000%",
    imageUrl: SPC,
    content: (
      <>
               <p className="mt-10" style={{ fontSize: "15px" }}>

          Listen to confident people speak you’ll start expressing yourself
          confidently…
          <br />
          Experiencing an energy awakens it’s likeness within you.
          <br />
          <br />
          It is often said that “Iron sharpeneth iron.” Another brazing truth is
          “Show your friends I’ll tell who you are”, or “Birds of a feather
          flock together.” However, the incontrovertible fact is that “Like
          calls unto like, deep unto deep.” These are all time realities and
          knowing them indeed is wisdom.
          <br />
          <br />
          Listen to confident people speak, people will say you’re confident as
          all you’ll know to be is that. Just like computers- (garbage-in
          garbage-out) what you ingest, you’ll digest and project.
          <br />
          <br />
          That’s why they say “read a lot” because you’ll speak only knowledge!
          …that’s literally all you’ll have to give!
          <br />
          <br />
          Practice makes improvement! Keep ingesting all you want to be.
          <br />
          <br />
          Curate on your content diet, you’ll create the reality you previously
          thought could only be imagined.
        </p>
      </>
    ),
    category: "Thoughts to chop",
    date: "June 01, 2023",
    minsRead: "1min read",
    relatedBlogs: [
      {
        relatedimageUrl: TAT,
        title: "Meditation or Insomnia",
        link: "/ktn/thought-to-chop/meditation-or-insomnia",
      },
      {
        relatedimageUrl: STBGG,
        title: "Going to the gym isn't neccessary",
        link: "/ktn/thought-to-chop/gym-isnt-neccessary",
      },
      {
        relatedimageUrl: MYLWTA,
        title: "How original is your life",
        link: "/ktn/thought-to-chop/how-original-is-your-life",
      },
    ],
  };

  return <ReadKtnProps blog={blogData} />;
};

export default GrowYourConfidence;
