import React from "react";
import FeaturedPostConts from "./FeaturedPostConts"; 
import "./FeaturedPost.css"; 
import { NavLink } from "react-router-dom";


const FeaturedPost = () => {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <NavLink to="/ktn/afroprenuership/the-secret-to-a-bestselling-book">
        <img
          src={FeaturedPostConts.image}
          alt={FeaturedPostConts.title}
          className="w-full h-auto object-cover featured-image"
        />
        <div className="p-6">
          <h2 className="text-xl md:text-3xl  font-bold mb-2 hover:underline">
            {FeaturedPostConts.title}
          </h2>
          <p className="text-gray-600 mb-4 text-xs md:text-sm">
            {FeaturedPostConts.author} - {FeaturedPostConts.date}
          </p>
          <p className="text-xs md:text-sm mb-0 md:mb-3">{FeaturedPostConts.description}</p>
        </div>
      </NavLink>
    </div>
  );
};

export default FeaturedPost;
