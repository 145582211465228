import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  <>
    <div className="mt-4 flex justify-center mb-20">
      {[...Array(totalPages).keys()].map((num) => (
        <button
          key={num + 1}
          onClick={() => onPageChange(num + 1)}
          className={`mx-1 px-3 py-1 rounded-md ${
            currentPage === num + 1
              ? "bg-[#14143a] text-white"
              : "bg-gray-300 text-gray-700 hover:bg-gray-400"
          }`}
        >
          {num + 1}
        </button>
      ))}
    </div>
    <hr className="border-0 bg-black" style={{height: '1px'}} />

  </>
);

export default Pagination;
