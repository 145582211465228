import React from "react";
import ReadKtnProps from "../ReadKtnProps";
import TEllYOURSTORY from "../../../Images/blogpics/thoughtofchop/tellyourstory.webp";
import FBS from "../../../Images/blogpics/thoughtofchop/FBS.webp";
import TRUTH from "../../../Images/blogpics/thoughtofchop/TAHEAD.webp";
import PSC from "../../../Images/blogpics/thoughtofchop/PSC.webp";

const TheTruth = () => {
  const blogData = {
    title: "The truth will get you ahead",
    imageUrl: TRUTH,
    content: (
      <>
               <p className="mt-10" style={{ fontSize: "15px" }}>

          Truth is the remedy for all internal struggles.
          <br />
          <br />
          It illuminates the path to inner peace and happiness.
          <br />
          <br />
          Align your thoughts, words and actions; you will discover your true
          purpose, and present yourself without cloaks.
          <br />
          <br />
          Embrace the clarity that comes with facing your truth.
          <br />
          <br />
          It is who you truly are.
          <br />
          <br />
          Truth is the catalyst for growth and transformation.
          <br />
          <br />
          Your wins will feel richer and your losses become sparks to shed
          elements of yourself you recognize as limiting.
          <br />
          <br />
          You take responsibility for the reality shaping around you.
          <br />
          <br />
          You are strengthened by the by the assuring thought that you can
          change whatever does not feel your ideal.
          <br />
          <br />
          You get fully immersed in your own nature and experience life as an
          original. You become liberated!
          <br />
          <br />
          While your thoughts may be an aggregate of the thoughts and
          experiences you have had over time, you will still ooze authenticity-
          ingenuity.
          <br />
          <br />
          Authenticity is embroidered in your ability to DISTINGUISH between
          logic and the dictates of establishments.
          <br />
          <br />
          You are your own person; transacting with the world as an independent,
          external contributor.
          <br />
          <br />
          What a way to live! It is the true way to be alive.
          <br />
          <br />
          Be honest with and about yourself, and find peace!
          <br />
          <br />
        </p>
      </>
    ),
    category: "Thoughts to chop",
    date: "February 07, 2024",
    minsRead: "1min read",
    relatedBlogs: [
      {
        relatedimageUrl: FBS,
        title: "Customer-Centric Marketing: The Foundation Of Business Success",
        link: "/ktn/thought-to-chop/customer-centric-marketing",
      },
      {
        relatedimageUrl: PSC,
        title: "Practical Strategies To Speak With Confidence",
        link: "/ktn/thought-to-chop/speak-with-confidence",
      },
      {
        relatedimageUrl: TEllYOURSTORY,
        title: "Tell your story",
        link: "/ktn/thought-to-chop/tell-your-story",
      },
    ],
  };

  return <ReadKtnProps blog={blogData} />;
};

export default TheTruth;
