import React from "react";
import ReadKtnProps from "../ReadKtnProps";
import NCSA from "../../../Images/blogpics/marketingsales/NCSA.webp";
import LRAC from "../../../Images/blogpics/marketingsales/LRAC.webp";
import WAH from "../../../Images/blogpics/marketingsales/WAH.webp";
import ML from "../../../Images/blogpics/marketingsales/ML.webp";

const RetainClients = () => {
  const blogData = {
    title: "How to land and retain clients",
    imageUrl: LRAC,
    content: (
      <>
               <p className="mt-10" style={{ fontSize: "15px" }}>

          Marketing isn’t presenting the full picture; it’s turning heads and
          hinting full picture!
          <br />
          <br />
          Start observing the picture they form then affirm their imaginations
          with complimentary products, brands, or services.
          <br />
          <br />
          It’s like Leonardo DiCaprio’s “inception”- Your goal is:
          <br />
          MAKE THEM REACH CONCLUSIONS ON THEIR OWN Otherwise, they feel cheated
          or coerced to purchase.
          <br />
          <br />
          When #Apple sells iPhones (**with a million different capabilities)
          they market features not the whole product.
          <br />
          <br />
          They create camera-quality ads and hint editing power. When you
          purchase, you get the camera, speakers, screen resolution, processor
          speed, multitasking capabilities, security features etc.
          <br />
          <br />
          In a market with high concern for Cyber security, guess which feature
          gets the higher ad budget.
          <br />
          <br />
          …Listen to your audience. Deliver what their mind makes your product
          out to be. You’re recording sales anyway!
          <br />
          <br />
          Whether they’ve purchased to use one feature or all, YOU SOLD.
          <br />
          <br />
          HERE’s THE KICKER
          <br />
          <br />
          You’ve not used your iPhone for all it can deliver I can bet on that;
          Sometimes just finding out stuff it can do justifies your purchase
          months down the line!
          <br />
          <br />
          Let your brand/product be a gift that keeps on giving.
          <br />
          <br />
          You DON’T have to say all you can deliver. Market one “best-seller”
          feature and when you reel them in, JUST KEEP GIVING!
          <br />
          <br />
          1.Be a no-brainer deal (multi-dimensional utility)
          <br />
          <br />
          2.Observe the needs of your market/audience
          <br />
          <br />
          3.Market the aspect of your offer they seem to need
          <br />
          <br />
          4.Be the solution to that pressing need
          <br />
          <br />
          5.Keep unfolding your multi-dimensional utility in time
          <br />
          <br />
          That’s how you LAND AND RETAIN buyers.
          <br />
        </p>
      </>
    ),
    category: "Marketing and sales",
    date: "June 21, 2023",
    minsRead: "1min read",
    relatedBlogs: [
      {
        relatedimageUrl: ML,
        title: "Marketing is just like law, convince the jury!",
        link: "/ktn/marketing-and-sales/marketing-law",
      },
      {
        relatedimageUrl: NCSA,
        title: "If you hate the feeling of selling read this",
        link: "/ktn/marketing-and-sales/hate-the-feeling-of-selling",
      },
      {
        relatedimageUrl: WAH,
        title: "Being of utility makes you a whore?",
        link: "/ktn/marketing-and-sales/whore",
      },
    ],
  };

  return <ReadKtnProps blog={blogData} />;
};

export default RetainClients;
