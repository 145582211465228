import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import ImageKick from '../../Images/ktnLogo/LogoKtnPage.webp';
import axios from 'axios';
import { Link } from "react-router-dom";
const NewsletterPopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.elements.name.value;
    const email = e.target.elements.email.value;

    const apiKey = 'mc5E05u5e4O7IlLiRJhm5w';
    const formId = '7263348';

    try {
      const response = await axios.post(`https://api.convertkit.com/v3/forms/${formId}/subscribe`, {
        api_key: apiKey,
        email: email,
        first_name: name,
      });

      if (response.data.subscription) {
        alert('You have successfully subscribed!');
      } else {
        alert('Subscription failed. Please try again.');
      }
    } catch (error) {
      alert('Error occurred. Please try again later.');
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div className="bg-white w-full max-w-3xl mx-auto flex flex-col md:flex-row rounded-lg shadow-lg overflow-hidden">
        {/* Left side image */}
        <div className="w-full md:w-1/2 h-48 md:h-auto">
          <img
            src={ImageKick}
            alt="Newsletter Promo"
            className="object-cover h-full w-full"
          />
        </div>

        {/* Right side form */}
        <div className="w-full md:w-1/2 p-6 md:p-8 relative">
          {/* Close button */}
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 focus:outline-none"
          >
            <AiOutlineClose className="h-6 w-6" />
          </button>

          {/* Content */}
          <h2 className="text-2xl md:text-3xl font-semibold mb-2">KTN COMMUNITY</h2>
          <p className="mb-6 text-gray-600 text-sm">
          Secure exclusive access to KTN!
Your backstage pass to our creative world, where we as Africans are boldly changing the narrative.

<br /><br />
Subscribe here!


          </p>

          {/* Form with name and email inputs */}
          <form className="mb-6 md:mb-10 text-sm" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:border-black mb-4"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Enter your email address"
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:border-black"
              required
            />
            <button
              type="submit"
              className="mt-4 w-full bg-[#14143a] hover:bg-indigo-600 text-white py-3 px-3 rounded-md transition duration-300"
            >
              Subscribe
            </button>

            <p className="mt-3 text-gray-600 text-center" style={{fontSize: '10px'}}>
            By entering your email you agree to receive updates on our latest discoveries 


          </p>
          </form>

          {/* Privacy Note */}

        

<p className="text-xs text-gray-500 text-center" style={{fontSize: '10px'}}>
  Please read our{" "}
  <Link to="/privacy-policy" className="text-blue-500 underline">
    Privacy Policy
  </Link>{" "}
  to see how we use your personal information.
</p>

          
        </div>
      </div>
    </div>
  );
};

export default NewsletterPopup;
