import React, { useEffect, useState } from "react";
import MarketingImage from "../../../Images/pages/marketingPage.webp";
import Rebecca from "../../../Images/testimonialpics/rebecca.webp";
import Eze from "../../../Images/testimonialpics/eze.webp";
import Ag from "../../../Images/testimonialpics/ag.webp";

const testimonials = [
  
  {
    name: "Ag Goldsmith",
    role: (
      <>
        Finance Director, Hendrick Toyota Scion,
        <br />
        Charleston, South Carolina, United States.
      </>
    ),
    image: Ag,
    rating: 5,
    header: "Accountability Partners",
    testimonial:
      "This is a great team! Very intellectual, and efficient, and serves as great accountability partners for projects you work on.",
  },
  {
    name: "Rebecca Jenkins",
    role: (
      <>
        Founder at RJEN,
        <br />
        Bristol, United Kingdom.
      </>
    ),
    image: Rebecca,
    rating: 5,
    header: "Kick, You Walk The Talk",
    testimonial:
      "Kick, you walk the talk. Your range of services to help budding authors share their message with a wider audience is vital in a world of fake news, turmoil, and constant change. I always learn and am inspired when exploring the experiences of others who have faced challenges. It lifts both spirits and potential.",
  },
  {
    name: "Douglas Eze, CFF",
    role: (
      <>
        Founder & CEO, Largo Financial Services LLC,
        <br />
        Washington DC-Baltimore Area, United States.
      </>
    ),
    image: Eze,
    rating: 5,
    header: "Truly Remarkable",
    testimonial:
      "I love seeing a band of young people come together to create something and you have created something truly remarkable here; I happily recommend your services to people",
  },
];

const VideoContentEditing = () => {
 const [loaded, setLoaded] = useState(false);

 const [currentIndex, setCurrentIndex] = useState(0);

 const next = () => {
   setCurrentIndex((prevIndex) => (prevIndex + 3) % testimonials.length);
 };

 const prev = () => {
   setCurrentIndex(
     (prevIndex) => (prevIndex - 3 + testimonials.length) % testimonials.length
   );
 };

 const getCurrentTestimonials = () => {
   return testimonials.slice(currentIndex, currentIndex + 3);
 };

  useEffect(() => {
    // After component mounts, set loaded to true to trigger animation
    setLoaded(true);
  }, []);

 
  return (
    <>
      <div
        className="relative bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${MarketingImage})` }}
      >
        <div className="relative bg-gray-900 bg-opacity-75 py-44 mt-10 md:py-60 md:mt-32">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className={`text-center ${loaded ? "slide-in" : ""}`}>
              <h1 className="font-extrabold text-white text-3xl md:text-5xl">
                Preserve your essence
              </h1>
              <p className="mt-10 text-xl text-gray-300">
            
              Tell us your idea and vision for your brand, products, or
                services, We’ll create visually compelling content to endear
                your audience to you, enough so you remain top of mind and close
                more sales.
              </p>
              <div className="mt-10 flex justify-center">
                <a
                  href="https://calendly.com/kalukalu/discovery-call"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-[#14143a] hover:bg-indigo-600 text-white py-4 px-3 rounded-lg text-sm font-semibold mr-4 transition duration-300"
                >
                  Free Discovery Call
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="max-w-[1600px] mx-auto py-12">
      <h3 className="text-center text-blue-500 mb-4 text-md md:text-lg font-bold">
        A+ RATED GHOSTWRITING AND MARKETING SERVICES
      </h3>
      <h2 className="text-3xl font-bold text-center mb-12">
        Recent Testimonials
      </h2>
      <div className="flex justify-between items-center">
        <button
          onClick={prev}
          className="text-3xl p-2 text-gray-600 hover:text-gray-800"
        >
          {"<"}
        </button>
        <div className="flex justify-center space-x-4 overflow-hidden">
          {getCurrentTestimonials().map((item, index) => (
            <div
              key={index}
              className="bg-white border border-gray-300 shadow-md rounded-lg p-6 w-96 h-[700px] flex-shrink-0 flex flex-col justify-between"
            >
              <div className="flex-grow">
                <div className="flex justify-between items-center mb-4">
                  <div>
                    {[...Array(item.rating)].map((_, i) => (
                      <span key={i} className="text-yellow-500">
                        ★
                      </span>
                    ))}
                  </div>
                </div>
                <p className="text-lg font-bold">{item.header}</p>
                <p className="text-gray-600 mb-4 mt-6 whitespace-pre-line">
                  {item.testimonial}
                </p>
              </div>
              <div className="flex items-center">
                <img
                  src={item.image}
                  alt={item.name}
                  className="w-12 h-12 rounded-full mr-4"
                />
                <div>
                  <p className="font-bold">{item.name}</p>
                  <p className="text-sm">{item.role}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button
          onClick={next}
          className="text-3xl p-2 text-gray-600 hover:text-gray-800"
        >
          {">"}
        </button>
      </div>
    </div>

      <div className="flex flex-col md:flex-row bg-blue-100 p-4 mb-10 md:p-10">
        <div className="flex-1 text-black p-6 md:p-0 md:text-sm md:pr-4">
          We’ll make sure your image is recognizable then track your customers
          to position you right in their faces. If they aren’t set to buy what
          you’re selling, they’ll willingly market you to their circle. That’s
          how good we make you look. Your avatar’s network is a treasure trove
          of potential customers! If “network” is your “net worth”, their
          network is our homework. we market your brand so it sells itself.
        </div>
        <a
          href="https://calendly.com/kalukalu/ghostwriting-marketing-cconsultation?month=2023-09"
          target="_blank"
          rel="noopener noreferrer"
          className="mt-4 md:mt-0 md:ml-4 block bg-blue-500 hover:bg-red-600 text-white font-bold py-2 px-4 md:py-4 md:px-4 rounded text-center
                   sm:py-3 sm:px-6 sm:text-sm"
          // Adjusted padding and margins for better mobile and larger screen layout
        >
          LET'S MEET
        </a>
      </div>
      {/* <VideoComponent src={Video1} /> */}
    </>
  );
};

export default VideoContentEditing;
