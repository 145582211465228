import React from "react";
import MarketingOne from '../../../Images/ServicesImages/MarketingOne.jpg';
import MarketingTwo from '../../../Images/ServicesImages/MarketingTwo.jpg';

const MarketingServices = () => {
  // Array of services with image URLs, labels, and overlay text
  const services = [
    {
      image: MarketingOne,
      label: "Brand Strategy",
      text: `A life worth talking about must first be lived… you exist to experience life and express yourself. Tell us how the world should perceive you, we’ll make it happen.`,
    },
    {
      image: MarketingTwo,
      label: "Video Production",
      text: `Tell us your vision for your brand, products, or services. We'll create visually compelling content to endear your audience to you, enough so you remain top of mind and close more sales.`,
    },
  ];

  return (
    <div className="max-w-4xl mx-auto py-10 px-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
      {services.map((service, index) => (
        <div
          key={index}
          className="relative group overflow-hidden rounded-lg shadow-lg cursor-pointer"
        >
          {/* Image */}
          <img
            src={service.image}
            alt="Ghostwriting Service"
            className="w-full h-96 object-cover transition-transform duration-500 group-hover:scale-110 group-hover:blur-sm"
          />

          {/* Label below the image */}
          <div className="text-center bg-gray-800 text-white text-sm font-semibold py-5">
            {service.label}
          </div>

          {/* Overlay text with HTML */}
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-500 p-4 text-center">
            <p
              className="text-white text-md font-semibold"
              dangerouslySetInnerHTML={{ __html: service.text }} // Render HTML
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default MarketingServices;
